import React, { useState } from "react";
import styled from "styled-components";
import { Input as AntdInput } from "antd";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 12px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #00000073;
  margin-bottom: 4px;
`;
const Input = styled(AntdInput)`
  border-radius: 2px;
  padding: 8px;
`;

interface Props {
  search: string;
  onChange: (search: string) => void;
  label: string;
  placeHolder?: string;
}

const InputFilter = (props: Props) => {
  const [text, setText] = useState<string>("");
  const { search, onChange, label, placeHolder } = props;
  return (
    <Container>
      <Title>{label}</Title>
      <Input
        placeholder={placeHolder}
        type="text"
        defaultValue={search}
        onChange={e => {
          const { value } = e.target;
          setText(value);
        }}
        onKeyUp={e => {
          if (e.key === "Enter" || e.key === "Return") {
            if (text) {
              if (text !== search) onChange(text);
            } else {
              onChange("");
            }
          }
        }}
      />
    </Container>
  );
};

export default InputFilter;
