import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Table from "../../components/table";
import InputFilter from "../../components/inputFilter";
import useValueStore from "../../stores/values";
import Button from "../../components/button";
import useAuthStore from "../../stores/auth";
import { ColumnsType } from "antd/es/table";
import useFranchiseeUserStore from "../../stores/franchiseeUser";
import FranchiseeUserForm from "../form/franchiseeUserForm";
import RadioFilter from "../../components/radioFilter";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 24px;
  flex: 1;
  overflow: hidden;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  border: 1px solid #f0f0f0;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const SearchWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  border: 1px solid #f0f0f0;
  border-top: 0px;
`;
const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  padding: 10px 24px;
  justify-content: space-between;
  border: 1px solid #f0f0f0;
  border-top: 0px;
`;
const TableHeader = styled.div`
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TableHeaderButtonWrapper = styled.div``;

const FranchiseeUserPage = () => {
  const { connect, setConnect, franchiseeUsers, totalLength, addFranchiseeUser, setSearch, setTotalLength, search, resetFranchiseeUserStore } =
    useFranchiseeUserStore();
  const { filterList } = useValueStore();
  const [selectFranchisee, setSelectFranchisee] = useState();
  const { apiFetch } = useAuthStore();

  useEffect(() => {
    return resetFranchiseeUserStore;
  }, []);

  useEffect(() => {
    if (franchiseeUsers.length === 0) update();
  }, [search, connect]);

  const limit = 50;
  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: "아이디",
        dataIndex: "id",
        key: "id"
      },
      {
        title: "이름",
        dataIndex: "name",
        key: "name",
        className: "min-width-table table-headline",
        ellipsis: true
      },
      {
        title: "프랜차이즈",
        dataIndex: "franchisee.id",
        render: (_, { franchisee }) => {
          return franchisee ? franchisee.name : "";
        },
        width: 120
      }
    ],
    []
  );

  const data = useMemo(() => franchiseeUsers, [franchiseeUsers]);
  const update = () => {
    const offset = franchiseeUsers.length;
    let apiPath = `/api/franchisee/user?limit=${limit}&offset=${offset}&search=${search}&connect=${connect}`;
    apiFetch(apiPath, "GET").then(data => {
      addFranchiseeUser(data[0]);
      setTotalLength(data[1]);
    });
  };
  const onClickFn = (row: any) => {
    console.log(row);
    setSelectFranchisee(row);
    // apiFetch(`/api/franchisee/${row.id}`, "GET").then(data => {
    //   setSelectFranchisee(data.data);
    // });
  };

  const onChangeFilter = (value: string) => {
    setSearch(value);
  };

  const newItem = () => {
    setSelectFranchisee(undefined);
  };
  const onChangeConnect = (value: string) => {
    setConnect(value);
  };

  return (
    <PageWrapper>
      <ContentWrapper>
        <SearchWrapper>
          <InputFilter onChange={onChangeFilter} search={search} placeHolder="이름" label="이름 검색" />
          <RadioFilter selectList={filterList.connectType} onChange={onChangeConnect} label={"프랜차이즈연결"} selectValue={connect} />
        </SearchWrapper>
      </ContentWrapper>
      <TableHeaderWrapper>
        <TableHeader>{`프랜차이즈 유저 정보 (총 ${totalLength}개)`}</TableHeader>
        <TableHeaderButtonWrapper>
          <Button title="+ 신규 프랜차이즈 유저 생성" onClick={newItem} />
        </TableHeaderButtonWrapper>
      </TableHeaderWrapper>
      <ContentWrapper style={{ overflow: "scroll" }}>
        <Table selectItem={selectFranchisee} totalCount={totalLength} columns={columns} data={franchiseeUsers} update={update} onClick={onClickFn} />
        <FranchiseeUserForm franchiseeUser={selectFranchisee} onEnd={newItem} />
      </ContentWrapper>
    </PageWrapper>
  );
};

export default FranchiseeUserPage;
