import React from "react";
import styled from "styled-components";
import { Table as AntdTable } from "antd";
import { useVT } from "virtualizedtableforantd4";
import _ from "lodash";

interface Props {
  columns: any[];
  data: any;
  update: () => void;
  onClick?: any;
  totalCount?: number;
  style?: React.CSSProperties;
  selectItem?: any;
}
const TableWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 20px;
  border-right: 1px solid #f0f0f0;
  min-width: calc(50% - 40px);
`;

const scrollConfig = {
  y: "calc(100vh - 330px)"
};

const Table = (props: Props) => {
  const { columns, data, update, onClick, totalCount, style, selectItem } = props;

  const [vt] = useVT(
    () => ({
      onScroll: _.throttle(({ isEnd }) => {
        if (isEnd) {
          update();
        }
      }, 1000),
      offset: 50,
      scroll: { y: scrollConfig.y },
      debug: true
    }),
    [totalCount, data]
  );

  return (
    <TableWrapper style={style}>
      <AntdTable
        onRow={record => {
          return {
            onClick: e => {
              onClick(record);
            }
          };
        }}
        rowKey={"id"}
        dataSource={data}
        scroll={scrollConfig}
        components={vt}
        columns={columns}
        pagination={false}
        rowClassName={recode => (recode.id == selectItem?.id ? "table-row-selected" : "")}
      />
    </TableWrapper>
  );
};

export default Table;
