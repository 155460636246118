import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import MSelectSearch from "../../components/formSelectSearch";
import Button from "../../components/button";
import useAuthStore from "../../stores/auth";
import { toast } from "react-toastify";
import FormContent from "../../components/formContent";
import useFranchiseeUserStore from "../../stores/franchiseeUser";

const FormWrapper = styled.div`
  display: flex;
  flex: 1 1 0%;
  overflow: hidden;
  padding: 20px;
`;
const FormInnerWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow-y: scroll;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: fit-content;
`;

const FormSection = styled.div`
  padding: 8px 24px;
`;
const FormTitleWrapper = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const FormTitle = styled.div`
  font-size: 18px;
`;
const FormButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
`;
const Input = styled.input`
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 0 10px;
`;
const FranchiseeUserForm = (props: { franchiseeUser?: TFranchiseeUser; onEnd?: () => void }) => {
  const { franchiseeUser } = props;
  const [isFetch, setIsFetch] = useState<boolean>(false);
  const { updateFranchiseeUser, deleteFranchiseeUser, addFranchiseeUser } = useFranchiseeUserStore();
  const { apiFetch } = useAuthStore();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: franchiseeUser
  });

  const submit = handleSubmit(sendData => {
    if (isFetch) {
      return;
    }
    if (!sendData.franchisee) {
      toast("프랜차이즈를 설정해주세요..", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
      return;
    }
    const reqData = { ...sendData, franchisee_id: sendData.franchisee.value };
    setIsFetch(true);
    if (!franchiseeUser) {
      if (!sendData.password) {
        toast("비밀번호를 설정해주세요..", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        });
        return;
      }
      apiFetch(`/api/franchisee/user`, "POST", reqData)
        .then(data => {
          addFranchiseeUser([data.result]);
          if (props.onEnd) {
            props.onEnd();
          }
          reset({ id: "", password: "", name: "", franchisee: undefined });
          toast("저장되었습니다.", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          });
        })
        .catch(err => {
          toast(err.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          });
        });
    } else {
      apiFetch(`/api/admin/franchisee/user`, "PUT", reqData)
        .then(data => {
          updateFranchiseeUser(data.result);
          toast("저장되었습니다.", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          });
        })
        .catch(err => {
          toast(err.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          });
        });
    }
    setIsFetch(false);
  });

  const customerGenerationOption = (option: TFranchisee) => {
    console.log(option);
    return {
      value: option.id,
      label: option.name
    };
  };

  const deleteItemFn = () => {
    if (!franchiseeUser) {
      return;
    }
    apiFetch(`/api/admin/franchisee/user`, "DELETE", { id: franchiseeUser.id })
      .then(() => {
        deleteFranchiseeUser(franchiseeUser);
        reset({ id: "", password: "", name: "", franchisee: undefined });
        toast("삭제되었습니다.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        });
      })
      .catch(err => {
        toast(err.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        });
      });
  };

  useEffect(() => {
    if (!franchiseeUser) {
      reset({ id: "", password: "", name: "", franchisee: undefined });
    } else {
      console.log(franchiseeUser);
      reset({ ...franchiseeUser, password: "" });
    }
  }, [franchiseeUser]);

  return (
    <FormWrapper>
      <FormInnerWrapper>
        <Form onSubmit={submit}>
          <FormSection>
            <FormTitleWrapper>
              <FormTitle>기본정보</FormTitle>
            </FormTitleWrapper>
            <FormContent title="아이디">
              <Input
                disabled={franchiseeUser ? true : false}
                {...register("id", { required: true, validate: (value, formValues) => value !== "" })}
              />
            </FormContent>
            <FormContent title="비밀번호">
              <Input placeholder="신규생성 또는 비민번호 변경시에만 입력해주세요" type={"password"} {...register("password", { required: false })} />
            </FormContent>
            <FormContent title="이름">
              <Input {...register("name", { required: true, validate: (value, formValues) => value !== "" })} />
            </FormContent>
            <FormContent title="회원가입 메모">
              <Input disabled placeholder="" {...register("memo", { required: false })} />
            </FormContent>
            <FormContent title="프랜차이즈">
              <MSelectSearch
                control={control}
                emptyFetch
                name={`franchisee`}
                apiPath={"/api/franchisee/search?"}
                placeholder={"프랜차이즈이름"}
                generateOption={customerGenerationOption}
              />
            </FormContent>
          </FormSection>
          <FormSection>
            <FormTitleWrapper>
              <FormTitle></FormTitle>
              <FormButtonWrapper>
                {franchiseeUser && <Button onClick={deleteItemFn} danger style={{ padding: "0 50px" }} type={"button"} title={"삭제"} />}
                <Button style={{ padding: "0 50px" }} type={"submit"} title={"저장"} />
              </FormButtonWrapper>
            </FormTitleWrapper>
          </FormSection>
        </Form>
      </FormInnerWrapper>
    </FormWrapper>
  );
};

export default FranchiseeUserForm;
