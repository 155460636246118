import { DatePicker } from "antd";
import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import useValueStore from "../stores/values";

type CustomSelectProps<T> = {
  onChange?: (event: string) => void;
  value: string;
  label?: string;
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 12px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #00000073;
  margin-bottom: 4px;
`;

const MonthFilter = (props: CustomSelectProps<string>) => {
  const { label, onChange, value } = props;
  const { datePickerLocale } = useValueStore();
  return (
    <Container>
      <Title>{label}</Title>
      <DatePicker
        locale={datePickerLocale}
        picker="month"
        size={"large"}
        format={"YYYY-MM"}
        value={value && value !== "" ? dayjs(value, "YYYY-MM") : undefined}
        onChange={value => {
          if (onChange) {
            if (value) onChange(value.format("YYYY-MM"));
            else onChange("");
          }
        }}
      />
    </Container>
  );
};

export default MonthFilter;
