import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Table from "../../components/table";
import InputFilter from "../../components/inputFilter";
import useValueStore from "../../stores/values";
import Button from "../../components/button";
import useAuthStore from "../../stores/auth";
import { ColumnsType } from "antd/es/table";
import Label from "../../components/Label";
import RadioFilter from "../../components/radioFilter";
import useHolidayStore from "../../stores/holiday";
import moment from "moment";
import HolidayForm from "../form/holidayForm";
import MonthFilter from "../../components/monthFilter";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 24px;
  flex: 1;
  overflow: hidden;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  border: 1px solid #f0f0f0;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const SearchWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  border: 1px solid #f0f0f0;
  border-top: 0px;
`;
const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  padding: 10px 24px;
  justify-content: space-between;
  border: 1px solid #f0f0f0;
  border-top: 0px;
`;
const TableHeader = styled.div`
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TableHeaderButtonWrapper = styled.div``;

const HolidayPage = () => {
  const {
    addHoliday,
    setSearch,
    setTotalLength,
    holidays,
    search,
    totalLength,
    resetHolidayStore,
    setMonth,
    month,
    isEnd,
    setIsEnd,
    useYn,
    setUseYn
  } = useHolidayStore();
  const { filterList } = useValueStore();
  const [selectHoliday, setSelectHoliday] = useState<any>();
  const { apiFetch } = useAuthStore();
  useEffect(() => {
    return resetHolidayStore;
  }, []);
  useEffect(() => {
    if (holidays.length === 0) update();
  }, [search, month, holidays.length, isEnd, useYn]);

  const limit = 50;

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: "날짜",
        className: "min-width-table table-headline",
        width: 200,
        render: (row: any) => moment(row.date).format("YYYY-MM-DD")
      },
      {
        title: "사용여부",
        width: 100,
        render: (row: any) => <Label value={row.useYn ? "G" : "R"} title={row.useYn ? "사용" : "미사용"} />
      },
      {
        title: "사유",
        dataIndex: "reason",
        key: "reason",
        ellipsis: true
      },
      {
        title: "추가날짜",
        width: 200,
        render: (row: any) => moment(row.created_at).format("YYYY-MM-DD")
      }
    ],
    []
  );

  const update = () => {
    const offset = holidays.length;
    let apiPath = `/api/holiday?limit=${limit}&offset=${offset}&search=${search}&month=${month}&isEnd=${isEnd}&useYn=${useYn}`;
    apiFetch(apiPath, "GET").then(data => {
      addHoliday(data[0]);
      setTotalLength(data[1]);
    });
  };
  const onClickFn = (row: any) => {
    setSelectHoliday(row);
  };

  const onChangeFilter = (value: string) => {
    setSearch(value);
  };

  const newHoliday = () => {
    setSelectHoliday(undefined);
  };

  const onChangeMonth = (value: string) => {
    setMonth(value);
  };
  const onChangeIsEnd = (value: string) => {
    setIsEnd(value);
  };
  const onChangeUseYn = (value: string) => {
    setUseYn(value);
  };
  return (
    <PageWrapper>
      <ContentWrapper>
        <SearchWrapper>
          <InputFilter onChange={onChangeFilter} search={search} placeHolder="휴일사유" label="휴일 검색" />
          <MonthFilter onChange={onChangeMonth} value={month} label="월 선택" />
          <RadioFilter selectList={filterList.isEnd} onChange={onChangeIsEnd} label={"종료"} selectValue={isEnd} />
          <RadioFilter selectList={filterList.stateType} onChange={onChangeUseYn} label={"사용여부"} selectValue={useYn} />
        </SearchWrapper>
      </ContentWrapper>
      <TableHeaderWrapper>
        <TableHeader>{`휴일정보 (총 ${totalLength}개)`}</TableHeader>
        <TableHeaderButtonWrapper>
          <Button title="+ 신규 휴일 생성" onClick={newHoliday} />
        </TableHeaderButtonWrapper>
      </TableHeaderWrapper>
      <ContentWrapper style={{ overflow: "scroll" }}>
        <Table selectItem={selectHoliday} totalCount={totalLength} columns={columns} data={holidays} update={update} onClick={onClickFn} />
        <HolidayForm resetHoliday={newHoliday} holiday={selectHoliday} />
      </ContentWrapper>
    </PageWrapper>
  );
};

export default HolidayPage;
