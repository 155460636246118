import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import styled from "styled-components";
import { ISelectItem } from "../../components/formSelect";
import MSelectSearch from "../../components/formSelectSearch";
import useCustomerItemStore from "../../stores/customerItem";
import useAuthStore from "../../stores/auth";
import MCheckBox from "../../components/fromCheckbox";
import Button from "../../components/button";
import { toast } from "react-toastify";
import FormContent from "../../components/formContent";

const FormWrapper = styled.div`
  display: flex;
  flex: 1 1 0%;
  overflow: hidden;
  padding: 20px;
`;
const FormInnerWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow-y: scroll;
`;

const Form = styled.form`
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: fit-content;
`;

const FormSection = styled.div`
  padding: 8px 24px;
`;
const FormTitleWrapper = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const FormAppUserWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
`;
const FormAppUserContent = styled.div`
  flex: 1;
  font-size: 18px;
  word-break: break-all;
`;
const FormTitle = styled.div`
  font-size: 18px;
`;
const FormButtonWrapper = styled.div``;
const FormSwitchWrapper = styled.div<{ borderNone?: boolean }>`
  display: flex;
  ${props => (props.borderNone ? "" : "border: 1px solid #f0f0f0;")}
  flex: 1;
  align-items: center;
  justify-content: space-around;
`;
const FormSwitchTitle = styled.div``;
const FormDesc = styled.div`
  display: flex;
  align-items: center;
`;
const FormListWrapper = styled.div`
  border-bottom: 1px solid #d9d9d9;
`;
const CustomerItemForm = (props: {
  customerCommon?: TCustomerCommon & {
    customerItems: ({ id?: number; item?: TItem | ISelectItem; itemHasCompany?: TItemHasCompany | ISelectItem } | undefined)[];
    user_customers: {
      id?: number;
      permission: boolean;
      appUser?: {
        id: string;
        name: string;
        position: string;
      };
    }[];
  };
}) => {
  const { customerCommon } = props;
  const [isFetch, setIsFetch] = useState<boolean>(false);
  const { updateCustomerCommons } = useCustomerItemStore();
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: customerCommon ? customerCommon : {}
  });
  const { apiFetch } = useAuthStore();
  const { fields, append, remove, update, replace } = useFieldArray({
    control,
    name: "customerItems"
  });

  // const watchCustomerItems = watch("customerItems");
  // console.log("watch", watchCustomerItems);

  const submit = handleSubmit(data => {
    if (isFetch) {
      return;
    }
    setIsFetch(true);
    const customerItems = data.customerItems;
    const itemNotValue = _.find(customerItems, customerItem => customerItem?.item === undefined || customerItem?.item === null);
    const itemHasCompanyNotValue = _.find(
      customerItems,
      customerItem => customerItem?.itemHasCompany === undefined || customerItem?.itemHasCompany === null
    );
    if (itemNotValue || itemHasCompanyNotValue) {
      toast("특수품목을 확인해주세요.", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
      setIsFetch(false);
      return;
    } else {
      const newCustomerItems = _.map(data.customerItems, customerItem => {
        return {
          ...customerItem,
          item: {
            ...customerItem?.item,
            id: customerItem?.item?.id ? customerItem?.item?.id : customerItem?.item?.value
          },
          itemHasCompany: {
            ...customerItem?.itemHasCompany,
            id: customerItem?.itemHasCompany?.id ? customerItem?.itemHasCompany?.id : customerItem?.itemHasCompany?.value
          }
        };
      });
      const sendData = {
        ...data,
        customerItems: newCustomerItems
      };
      apiFetch("/api/customer", "PUT", sendData).then(data => {
        updateCustomerCommons(data);
        toast("저장되었습니다.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        });
        setIsFetch(false);
      });
    }
    setIsFetch(false);
  });

  const itemGenerationOption = (option: TItem) => {
    // if (option.id === undefined) {
    //   return {
    //     value: undefined,
    //     label: ""
    //   };
    // }
    return {
      value: option.id,
      label: option.itemKoName ? `${option.itemKoName}${option.itemVolume}ml/${option.itemEnName}` : ""
    };
  };

  const ihcGenerationOption = (option: TItemHasCompany) => {
    return {
      value: option.id,
      label: option.bona_item_code
        ? `${option.bona_item_code}/${option.item_name}/${option.item_state === "Y" && !option.show_disable ? "사용" : "미사용"}/${
            option.item_buyer
          }/${option.item_volume}ml/${option.item_in_count}병`
        : ""
    };
  };

  useEffect(() => {
    console.log(customerCommon);
    reset(customerCommon);
  }, [customerCommon]);

  const getStoreUse = () =>
    customerCommon && customerCommon.storeCategory2
      ? customerCommon.storeCategory2 === "1"
        ? "일반"
        : customerCommon.storeCategory2 === "2"
        ? "유흥"
        : ""
      : "";

  return (
    <FormWrapper>
      <FormInnerWrapper>
        <Form onSubmit={submit}>
          <FormSection>
            <FormTitleWrapper>
              <FormTitle>기본 정보</FormTitle>
              <FormButtonWrapper>
                <FormSwitchWrapper borderNone>
                  <FormSwitchTitle>VIP 거래처</FormSwitchTitle>
                  <MCheckBox disabled={!customerCommon} name="is_vip" control={control} />
                </FormSwitchWrapper>
              </FormButtonWrapper>
            </FormTitleWrapper>
            <FormContent title="보나뱅크코드">
              <FormDesc>{customerCommon?.bona_id}</FormDesc>
            </FormContent>
            <FormContent title="이름">
              <FormDesc>{customerCommon?.storeName}</FormDesc>
            </FormContent>
            <FormContent title="사업자번호">
              <FormDesc>
                {customerCommon?.storeRegNumber
                  ? customerCommon?.storeRegNumber.length >= 10
                    ? customerCommon.storeRegNumber.replace(/(\w{3})(\w{2})(\w{5})/g, "$1-$2-$3")
                    : customerCommon.storeRegNumber
                  : ""}
              </FormDesc>
            </FormContent>
            <FormContent title="상태">
              <FormDesc>
                {customerCommon && customerCommon.storeState
                  ? customerCommon.storeState === "1"
                    ? "정상"
                    : customerCommon.storeState === "2"
                    ? "영업정지"
                    : customerCommon.storeState === "3"
                    ? "휴업"
                    : customerCommon.storeState === "4"
                    ? "폐업"
                    : customerCommon.storeState === "5"
                    ? "단절"
                    : ""
                  : ""}
              </FormDesc>
            </FormContent>
            <FormContent title="판매타입">
              <FormDesc>{getStoreUse()}</FormDesc>
            </FormContent>
          </FormSection>
          <FormSection>
            <FormTitleWrapper>
              <FormTitle>특수 품목</FormTitle>
              <FormButtonWrapper>
                <Button
                  type="button"
                  title="신규 생성"
                  disabled={customerCommon === undefined}
                  onClick={() => {
                    append({
                      item: { id: undefined, value: undefined, label: undefined },
                      itemHasCompany: { id: undefined, value: undefined, label: undefined }
                    });
                  }}
                />
              </FormButtonWrapper>
            </FormTitleWrapper>
            {fields.map((item, index) => {
              const customerItemInnerItem = getValues(`customerItems.${index}.item`);
              const customerItemItem = getValues(`customerItems.${index}`);
              const item_id = customerItemInnerItem?.id || customerItemInnerItem?.value;
              return (
                <FormListWrapper key={`${item.id}`}>
                  <FormContent title="품목명">
                    <MSelectSearch
                      key={`item_select_${item.id}`}
                      control={control}
                      name={`customerItems.${index}.item`}
                      apiPath={`/api/multi/item?use=${getStoreUse()}&`}
                      emptyFetch
                      placeholder={"품목명"}
                      o_value={customerItemItem?.item}
                      onChange={(value: ISelectItem | undefined) => {
                        update(index, {
                          item: value,
                          itemHasCompany: undefined
                        });
                      }}
                      generateOption={itemGenerationOption}
                    />
                  </FormContent>
                  <FormContent title="보나코드 또는 이름">
                    <MSelectSearch
                      key={`ihc_select_${item.id}`}
                      control={control}
                      name={`customerItems.${index}.itemHasCompany`}
                      emptyFetch={item_id !== undefined}
                      disabled={item_id === undefined}
                      o_value={customerItemItem?.itemHasCompany}
                      apiPath={`/api/item/has/company/${item_id}?use=${getStoreUse()}&`}
                      placeholder={"보나코드 또는 이름"}
                      generateOption={ihcGenerationOption}
                    />
                    <Button
                      type="button"
                      onClick={() => {
                        remove(index);
                      }}
                      danger
                      title="삭제"
                    />
                  </FormContent>
                </FormListWrapper>
              );
            })}
          </FormSection>
          <FormSection>
            <FormTitleWrapper>
              <FormTitle>앱 사용자</FormTitle>
            </FormTitleWrapper>
            {customerCommon &&
              customerCommon.user_customers.map(
                (user_customer, idx) =>
                  user_customer?.appUser && (
                    <>
                      {idx === 0 && (
                        <FormTitleWrapper>
                          <FormAppUserContent style={{ flex: 2 }}>아이디</FormAppUserContent>
                          <FormAppUserContent style={{ flex: 2 }}>이름</FormAppUserContent>
                          <FormAppUserContent>직급</FormAppUserContent>
                          <FormAppUserContent>승인여부</FormAppUserContent>
                        </FormTitleWrapper>
                      )}
                      <FormAppUserWrapper>
                        <FormAppUserContent style={{ flex: 2 }}>{`${user_customer?.appUser?.id}`}</FormAppUserContent>
                        <FormAppUserContent style={{ flex: 2 }}>{`${user_customer?.appUser?.name}`}</FormAppUserContent>
                        <FormAppUserContent>{`${user_customer?.appUser?.position === "ceo" ? "대표" : "매니저"}`}</FormAppUserContent>
                        <FormAppUserContent>{`${user_customer.permission ? "승인" : "미승인"}`}</FormAppUserContent>
                      </FormAppUserWrapper>
                    </>
                  )
              )}
          </FormSection>
          <FormSection>
            <FormTitleWrapper>
              <FormTitle></FormTitle>
              <FormButtonWrapper>
                <Button style={{ padding: "0 50px" }} type={"submit"} title={"저장"} />
              </FormButtonWrapper>
            </FormTitleWrapper>
          </FormSection>
        </Form>
      </FormInnerWrapper>
    </FormWrapper>
  );
};

export default CustomerItemForm;
