import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import useAuthStore from "../../stores/auth";
import Button from "../../components/button";
import { toast } from "react-toastify";
import useUserStore from "../../stores/user";
import MCheckBox from "../../components/fromCheckbox";
import FormContent from "../../components/formContent";

const Form = styled.form`
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: fit-content;
`;

const FormSection = styled.div`
  padding: 8px 24px;
`;
const FormTitleWrapper = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const FormTitle = styled.div`
  font-size: 18px;
`;
const FormButtonWrapper = styled.div``;
const FormSwitchWrapper = styled.div<{ borderNone?: boolean }>`
  display: flex;
  ${props => (props.borderNone ? "" : "border: 1px solid #f0f0f0;")}
  flex: 1;
  align-items: center;
  justify-content: space-around;
`;
const FormSwitchTitle = styled.div``;
const Input = styled.input`
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 0 10px;
`;

const UserForm = (props: { user?: any | undefined; submit: (user: any) => void }) => {
  const { user } = props;
  const [isFetch, setIsFetch] = useState<boolean>(false);
  const { updateUser } = useUserStore();
  const { apiFetch } = useAuthStore();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue
  } = useForm({
    defaultValues: user
      ? user
      : {
          id: "",
          password: "",
          userName: "",
          state: true
        }
  });

  const submit = handleSubmit(sendData => {
    if (isFetch) {
      return;
    }
    setIsFetch(true);
    if (user) {
      apiFetch(`/api/user`, "PUT", sendData).then(data => {
        updateUser(data);
        toast("저장되었습니다.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        });
      });
    } else {
      apiFetch(`/api/user`, "POST", sendData).then(data => {
        updateUser(data);
        props.submit(data);
        toast("저장되었습니다.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        });
      });
    }
    setIsFetch(false);
  });

  useEffect(() => {
    if (!user) {
      reset(
        {
          id: "",
          password: "",
          userName: "",
          state: true
        },
        {
          keepDirtyValues: false,
          keepValues: false,
          keepDefaultValues: false,
          keepDirty: false
        }
      );
    } else {
      reset({ ...user, password: "" });
    }
  }, [user]);

  return (
    <Form onSubmit={submit}>
      <FormSection>
        <FormTitleWrapper>
          <FormTitle>관리자 정보</FormTitle>
          <FormButtonWrapper>
            <FormSwitchWrapper borderNone>
              <FormSwitchTitle>사용여부</FormSwitchTitle>
              <MCheckBox name="state" control={control} />
            </FormSwitchWrapper>
          </FormButtonWrapper>
        </FormTitleWrapper>
        <FormContent title="아이디">
          <Input {...register("id", { required: true })} />
        </FormContent>
        <FormContent title="비밀번호">
          <Input type="password" {...register("password", { required: !user })} />
        </FormContent>
        <FormContent title="이름">
          <Input {...register("userName", { required: true })} />
        </FormContent>
      </FormSection>
      <FormSection>
        <FormTitleWrapper>
          <FormTitle></FormTitle>
          <FormButtonWrapper>
            <Button style={{ padding: "0 50px" }} type={"submit"} title={"저장"} />
          </FormButtonWrapper>
        </FormTitleWrapper>
      </FormSection>
    </Form>
  );
};

export default UserForm;
