import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BonaForm from "../form/bonaForm";
import Table from "../../components/table";
import InputFilter from "../../components/inputFilter";
import SelectFilter from "../../components/selectFilter";
import useBonaItemStore from "../../stores/bonaItem";
import useValueStore from "../../stores/values";
import useAuthStore from "../../stores/auth";
import _ from "lodash";
import Label from "../../components/Label";
import RadioFilter from "../../components/radioFilter";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 24px;
  flex: 1;
  overflow: hidden;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  border: 1px solid #f0f0f0;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const SearchWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  border: 1px solid #f0f0f0;
  border-top: 0px;
`;
const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  padding: 10px 24px;
  justify-content: space-between;
  border: 1px solid #f0f0f0;
  border-top: 0px;
  min-height: 40px;
`;
const TableHeader = styled.div`
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TableHeaderButtonWrapper = styled.div``;

const BonaItemPage = () => {
  const {
    items,
    totalLength,
    addItem,
    setTotalLength,
    search,
    setSearch,
    state,
    setState,
    connect,
    setConnect,
    type,
    setType,
    use,
    setUse,
    is_new,
    setIsNew,
    resetBonaItemStore
  } = useBonaItemStore();
  const { bonaType, YNType, itemUseType, setItemTypes, filterList } = useValueStore();
  const { apiFetch } = useAuthStore();
  const [item, setItem] = useState<any>(null);
  const limit = 30;

  useEffect(() => {
    apiFetch("/api/getItemType", "GET").then(itemTypes => {
      const types = _.map(itemTypes, itemType => ({ value: itemType.id, label: itemType.itemTypeName }));
      setItemTypes(types);
    });
    return resetBonaItemStore;
  }, []);

  useEffect(() => {
    if (items.length === 0) update();
  }, [search, state, connect, type, use, is_new]);

  const columns = React.useMemo(
    () => [
      {
        title: "코드",
        dataIndex: "bona_item_code",
        key: "bona_item_code",
        width: 100
      },
      {
        title: "주류명",
        dataIndex: "item_name",
        key: "item_name",
        className: "min-width-table table-headline",
        ellipsis: true
      },
      {
        title: "용량",
        dataIndex: "item_volume",
        key: "item_volume",
        width: 100
      },
      {
        title: "입수량",
        dataIndex: "item_in_count",
        key: "item_in_count",
        width: 80
      },
      {
        title: "타입",
        dataIndex: "type",
        key: "type",
        width: 100
      },
      {
        title: "사용여부",
        dataIndex: "item_state",
        key: "item_state",
        width: 120,
        render: (_, { item_state, show_disable }) => (
          <Label value={item_state === "N" || show_disable ? "R" : "N"} title={item_state === "N" || show_disable ? "미사용" : "사용"} />
        )
      }
    ],
    []
  );
  const data = React.useMemo(() => items, [items]);
  const update = () => {
    const offset = items.length;
    apiFetch(
      `/api/bona/item?offset=${offset}&limit=${limit}&search=${search}&state=${state}&type=${type}&connect=${connect}&use=${use}&is_new=${is_new}`,
      "GET"
    ).then(data => {
      addItem(data[0]);
      setTotalLength(data[1]);
    });
  };
  const onClickFn = (row: any) => {
    setItem(row);
  };

  const onChangeFilter = (value: string) => {
    setSearch(value);
  };

  const onChangeState = (value: string) => {
    setState(value);
  };

  const onChangeConnect = (value: string) => {
    setConnect(value);
  };

  const onChangeType = (value: string) => {
    setType(value);
  };

  const onChangeUse = (value: string) => {
    setUse(value);
  };

  const onChangeIsNew = (value: string) => {
    setIsNew(value);
  };

  return (
    <PageWrapper>
      <ContentWrapper>
        <SearchWrapper>
          <InputFilter onChange={onChangeFilter} search={search} label="주류 검색" placeHolder="제품명 또는 코드" />
          <SelectFilter width={120} selectList={itemUseType} onChange={onChangeUse} label={"판매타입"} selectValue={use} />
          <SelectFilter
            width={120}
            selectList={[{ label: "전체", value: "" }, ...bonaType]}
            onChange={onChangeType}
            label={"타입"}
            selectValue={type}
          />
          <RadioFilter selectList={filterList.stateType} onChange={onChangeState} label={"사용여부"} selectValue={state} />
          <RadioFilter selectList={filterList.connectType} onChange={onChangeConnect} label={"제품연결"} selectValue={connect} />
          <RadioFilter selectList={filterList.newItemType} onChange={onChangeIsNew} label={"신규 품목"} selectValue={is_new} />
        </SearchWrapper>
      </ContentWrapper>
      <TableHeaderWrapper>
        <TableHeader>{`보나뱅크 품목정보 (총 ${totalLength}개)`}</TableHeader>
        <TableHeaderButtonWrapper></TableHeaderButtonWrapper>
      </TableHeaderWrapper>
      <ContentWrapper
        style={{
          overflow: "scroll"
        }}
      >
        <Table selectItem={item} totalCount={totalLength} columns={columns} data={data} update={update} onClick={onClickFn} />
        <BonaForm item={item} />
      </ContentWrapper>
    </PageWrapper>
  );
};

export default BonaItemPage;
