import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAuthStore from "../../stores/auth";
import styled from "styled-components";
import { Form, Input, Button } from "antd";
const PageWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: rgba(247, 248, 250, 1);
`;
const Wrapper = styled.div`
display: flex;
background: #FFF;
border-radius: 20px;
overflow: hidden;
}
`;
const ImageWrapper = styled.div``;
const LoginWrapper = styled.div``;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 17% 0;
`;
const HeaderLogo = styled.div`
  width: 50%;
`;
const Header = styled.div`
  font-size: 20px;
  padding: 5%;
`;

type FieldType = {
  id?: string;
  password?: string;
};

const LoginPage = () => {
  const { setLogin, apiFetch } = useAuthStore();

  const notify = (text: string) => {
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light"
    });
  };

  const onFinish = (values: any) => {
    apiFetch("/api/login", "POST", values)
      .then(data => {
        localStorage.setItem("id_token", data.id_token);
        setLogin(true, data.id_token);
      })
      .catch(err => {
        notify(err.message);
      });
  };

  const onFinishFailed = (errorInfo: any) => {};

  return (
    <PageWrapper>
      <Wrapper>
        <ImageWrapper>
          <Image src="https://d19obghv5roqrz.cloudfront.net/image/login_order.jpg" />
        </ImageWrapper>
        <LoginWrapper>
          <HeaderWrapper>
            <HeaderLogo>
              <Image src="https://d17mmzdc0h66bb.cloudfront.net/image/logo.png" />
            </HeaderLogo>
            <Header>세왕상사 홈페이지 관리자페이지</Header>
          </HeaderWrapper>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600, paddingRight: "100px" }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item<FieldType> label="아이디:" name="id" rules={[{ required: true, message: "아이디를 입력해주세요" }]}>
              <Input />
            </Form.Item>

            <Form.Item<FieldType> label="비밀번호:" name="password" rules={[{ required: true, message: "비밀번호를 입력해주세요" }]}>
              <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button style={{ width: "100%" }} type="primary" htmlType="submit">
                로그인
              </Button>
            </Form.Item>
          </Form>
        </LoginWrapper>
      </Wrapper>
    </PageWrapper>
  );
};

export default LoginPage;
