import React, { useRef } from "react";
import { FieldValues, useController } from "react-hook-form";
import { TControl } from "../@types/formControl";
import styled from "styled-components";
import { Button } from "antd";

type CustomSelectProps<T> = {
  onChange: (event: any) => void;
};
type TProps<T extends FieldValues> = CustomSelectProps<T> & TControl<T>;

const ImageWrapper = styled.div`
  height: 300px;
  background: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
`;
const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;
const LoadImage = styled(Button)`
  font-size: 14px;
`;
const ChangeImage = styled(Button)`
  position: absolute;
  right: 24px;
  bottom: 10px;
  font-size: 14px;
`;
function MImage<T extends FieldValues>(props: TProps<T>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { name, rules, control, onChange } = props;
  const {
    field: { value }
  } = useController({
    name,
    rules,
    control
  });

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <ImageWrapper onClick={handleClick}>
      {value ? (
        <>
          <Image src={value} />
          <ChangeImage
            onClick={e => {
              e.stopPropagation();
              handleClick();
            }}
          >
            이미지 변경
          </ChangeImage>
        </>
      ) : (
        <LoadImage
          onClick={e => {
            e.stopPropagation();
            handleClick();
          }}
        >
          이미지 불러오기
        </LoadImage>
      )}
      <input ref={inputRef} type="file" style={{ display: "none" }} onChange={onChange} accept="image/*" />
    </ImageWrapper>
  );
}

export default MImage;
