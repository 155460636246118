import { Select } from "antd";
import React, { ReactNode, useEffect } from "react";
import styled from "styled-components";
export interface ISelectItem {
  label: ReactNode;
  value: string;
  selected?: boolean;
  disabled?: boolean;
  hidden?: boolean;
}

type CustomSelectProps<T> = {
  selectList: ISelectItem[];
  onChange?: (event: string) => void;
  valueChange?: (...param: any) => void;
  selectValue: string;
  label?: string;
  width?: number;
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 12px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #00000073;
  margin-bottom: 4px;
`;

const SelectFilter = (props: CustomSelectProps<string>) => {
  const { label, selectValue, selectList, onChange, valueChange, width } = props;

  useEffect(() => {
    if (valueChange && selectValue) {
      valueChange(selectValue);
    }
  }, [selectValue]);

  return (
    <Container>
      <Title>{label}</Title>
      <Select
        style={{ width }}
        size={"large"}
        defaultValue={selectValue}
        options={selectList}
        onChange={value => {
          if (onChange) {
            onChange(value);
          }
        }}
      />
    </Container>
  );
};

export default SelectFilter;
