import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 4px 0;
  min-height: 40px;
`;
const TitleWrapper = styled.div<{ top?: boolean }>`
  flex: 1;
  display: flex;
  min-width: 170px;
  align-items: ${props => (props.top ? "flex-start" : "center")};
`;
const Title = styled.div``;
const ContentWrapper = styled.div<{ top?: boolean }>`
  flex: 6;
  display: flex;
  gap: 16px;
  ${props =>
    props.top
      ? `
      & > div {
        width:100%;
      };
      `
      : ""}
`;

type FormContentProps = {
  children: React.ReactNode;
  title: string;
  top?: boolean;
};
const FormContent = (props: FormContentProps) => {
  return (
    <Wrapper>
      <TitleWrapper top={props.top}>
        <Title>{props.title}</Title>
      </TitleWrapper>
      <ContentWrapper top={props.top}>{props.children}</ContentWrapper>
    </Wrapper>
  );
};

export default FormContent;
