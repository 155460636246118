import React, { useEffect, useState } from "react";
import { FieldValues, useController } from "react-hook-form";
import { TControl } from "../@types/formControl";
import { DatePicker, Button } from "antd";
import moment from "moment";
import styled from "styled-components";
import useValueStore from "../stores/values";

type CustomCheckBoxProps<T> = {
  disabled?: boolean;
  onChange?: (value: any) => void;
};
type TProps<T extends FieldValues> = CustomCheckBoxProps<T> & TControl<T>;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  & > .ant-picker {
    visibility: hidden;
    width: 1px;
    height: 1px;
  }
`;
const DateContent = styled.div`
  display: flex;
  width: 100%;
  word-break: break-all;
`;
const DateWrapper = styled.div`
  flex: 1;
  padding: 10px;
  border: 1px solid #d9d9d9;
`;
const ButtonWrapper = styled.div``;
function MMultiDatePicker<T extends FieldValues>(props: TProps<T>) {
  const { name, rules, control, disabled } = props;
  const {
    field: { value, onChange }
  } = useController({
    name,
    rules,
    control
  });

  const [selectedDate, setSelectedDate] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const { datePickerLocale } = useValueStore();
  useEffect(() => {
    onChange(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    if (!value) {
      setSelectedDate([]);
    }
  }, [value]);

  const onValueChange = date => {
    const newDate = date.format("YYYY-MM-DD");
    if (selectedDate.includes(newDate)) {
      setSelectedDate([...selectedDate.filter(item => item !== newDate)]);
    } else {
      setSelectedDate([...selectedDate, newDate]);
    }
  };

  const dateRender = (currentDate, info) => {
    if (info.type !== "date") {
      return info.originNode;
    }
    const isSelected = selectedDate.includes(currentDate.format("YYYY-MM-DD"));
    let selectStyle = isSelected
      ? {
          zIndex: 2,
          display: "inlineBlock",
          width: "24px",
          height: "22px",
          lineHeight: "22px",
          backgroundColor: "#1890ff",
          color: "#fff",
          margin: "auto",
          borderRadius: "2px",
          transition: "background 0.3s, border 0.3s"
        }
      : {};
    return <div style={selectStyle}>{currentDate.date()}</div>;
  };

  const extraFooter = () => {
    return <Button onClick={() => setOpen(false)}>확인</Button>;
  };
  return (
    <Wrapper>
      <DateWrapper>
        <DateContent>{disabled ? moment(value).format("YYYY-MM-DD") : selectedDate.length > 0 ? selectedDate.join(",") : "선택해주세요"}</DateContent>
      </DateWrapper>
      <DatePicker
        renderExtraFooter={extraFooter}
        locale={datePickerLocale}
        open={open}
        cellRender={dateRender}
        onChange={onValueChange}
        showToday={false}
      />
      {!disabled && (
        <ButtonWrapper>
          <Button
            type="primary"
            onClick={() => {
              setOpen(true);
            }}
          >
            선택
          </Button>
        </ButtonWrapper>
      )}
    </Wrapper>
  );
}

export default MMultiDatePicker;
