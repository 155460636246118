import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import styled from "styled-components";
import useItemStore from "../../stores/item";
import MImage from "../../components/formImage";
import MSelect from "../../components/formSelect";
import MSelectSearch from "../../components/formSelectSearch";
import useValueStore from "../../stores/values";
import MCheckBox from "../../components/fromCheckbox";
import Button from "../../components/button";
import useAuthStore from "../../stores/auth";
import { toast } from "react-toastify";
import FormContent from "../../components/formContent";
interface BonaItemType {
  id: number;
  bona_item_code: string;
  item_name: string;
  item_volume: number;
  item_in_count: number;
  item_buyer: string;
  show_disable?: boolean;
  item_state: string;
}
const FormWrapper = styled.div`
  display: flex;
  flex: 1 1 0%;
  overflow: hidden;
  padding: 20px;
`;
const FormInnerWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow-y: scroll;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: fit-content;
`;

const FormSection = styled.div`
  padding: 8px 24px;
`;
const FormTitleWrapper = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const FormTitle = styled.div`
  font-size: 18px;
`;
const FormButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
`;
const FormSwitchWrapper = styled.div`
  display: flex;
  border: 1px solid #f0f0f0;
  flex: 1;
  align-items: center;
  justify-content: space-around;
  min-width: 160px;
`;
const FormSwitchTitle = styled.div``;
const Input = styled.input`
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 0 10px;
`;
const ItemForm = (props: { item?: any | undefined }) => {
  const { item } = props;
  const [isFetch, setIsFetch] = useState<boolean>(false);
  const { updateItem, deleteItem } = useItemStore();
  const { itemTypes, selectList } = useValueStore();
  const { apiFetch, uploadFileApi } = useAuthStore();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    getValues,
    watch
  } = useForm({
    defaultValues: item
      ? item
      : {
          company: null,
          item_has_companies: []
        }
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "item_has_companies"
  });
  watch("countLimit");
  watch("recommend");
  watch("forceShow");
  watch("item_has_companies");

  const submit = handleSubmit(sendData => {
    if (isFetch) {
      return;
    }
    setIsFetch(true);
    if (sendData.countLimit && sendData.count === null) {
      sendData.count = 0;
    }
    if (!sendData.recommend && sendData.recommendText !== null) {
      sendData.recommendText = "";
    }
    if (sendData.orderCount === "") {
      sendData.orderCount = null;
    }
    if (sendData.itemDesc === "") {
      sendData.itemDesc = null;
    }
    if (sendData.forceShow && (sendData.forceShowText === null || sendData.forceShowText === "")) {
      toast("강제노출 설명을 입력해주새요", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
      setIsFetch(false);
      return;
    }
    if (sendData.id) {
      apiFetch(`/api/item/${sendData.id}`, "PUT", sendData)
        .then(data => {
          updateItem(data);
          toast("저장되었습니다.", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          });
        })
        .catch(err => {
          toast(err.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          });
        });
    } else {
      apiFetch(`/api/item`, "POST", sendData)
        .then(data => {
          toast("저장되었습니다.", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          });
        })
        .catch(err => {
          toast(err.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          });
        });
    }
    setIsFetch(false);
  });

  const bonaGenerationOption = (option: BonaItemType) => {
    let state = "사용";
    if (option.show_disable || option.item_state === "N") {
      state = "미사용";
    }
    return {
      value: option.id,
      label: option.bona_item_code
        ? `${option.bona_item_code}/${state}/${option.item_name}/ㄱ${option.item_buyer}/${option.item_volume}ml/${option.item_in_count}병`
        : ""
    };
  };

  const deleteItemFn = () => {
    apiFetch(`/api/item/${item.id}`, "DELETE")
      .then(() => {
        deleteItem(item.id);
        reset({
          id: undefined,
          itemKoName: "",
          itemEnName: "",
          itemDesc: "",
          itemVintage: "",
          itemAlcolLvl: "",
          itemKind: "",
          imagePath: "",
          itemVolume: "",
          itemInCount: "",
          itemClass: "",
          itemAwards: "",
          itemBuyer: "",
          itemState: false,
          boxSales: false,
          disableRank: false,
          recommend: false,
          recommendText: "",
          forceShow: false,
          forceShowText: "",
          countLimit: false,
          count: 0,
          orderCount: null,
          itemType: null,
          itemTypeDetail: null,
          country: null,
          company: null,
          user: null,
          item_has_companies: []
        });
        toast("삭제되었습니다.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        });
      })
      .catch(err => {
        toast(err.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        });
      });
  };

  useEffect(() => {
    if (!item) {
      reset({
        itemKoName: "",
        itemEnName: "",
        itemDesc: "",
        itemVintage: "",
        itemAlcolLvl: "",
        itemKind: "",
        imagePath: "",
        itemVolume: "",
        itemInCount: "",
        itemClass: "",
        itemAwards: "",
        itemBuyer: "",
        itemState: false,
        boxSales: false,
        disableRank: false,
        recommend: false,
        recommendText: "",
        forceShow: false,
        forceShowText: "",
        countLimit: false,
        count: 0,
        orderCount: null,
        itemType: null,
        itemTypeDetail: null,
        country: null,
        company: null,
        user: null,
        item_has_companies: []
      });
    } else {
      reset(item);
    }
  }, [item]);

  const imageUpload = (e: any) => {
    uploadFileApi("/api/itemImageUpload", e.target.files).then(data => {
      let imagePath = data.url;
      if (data.url.indexOf("/Users/test/zugo-server-nodejs/public/uploads/") == 0) {
        imagePath = data.url.replace("/Users/test/zugo-server-nodejs/public/uploads/", "");
      }

      setValue("imagePath", imagePath);
    });
  };

  return (
    <FormWrapper>
      <FormInnerWrapper>
        <Form onSubmit={submit}>
          <MImage name="imagePath" control={control} onChange={imageUpload} />
          <FormSection>
            <FormTitleWrapper>
              <FormTitle>기본정보</FormTitle>
            </FormTitleWrapper>
            <FormContent title="이름">
              <Input {...register("itemKoName", { required: true, validate: (value, formValues) => value !== "" })} />
            </FormContent>
            <FormContent title="영문명">
              <Input {...register("itemEnName")} />
            </FormContent>
            <FormContent title="용량(ml)">
              <Input {...register("itemVolume", { required: true })} />
            </FormContent>
            <FormContent title="주류 종류">
              <MSelect selectList={itemTypes} placeholder="선택해주세요" control={control} name="itemType.id" />
            </FormContent>
            <FormContent title="수입제조사">
              <Input {...register("itemBuyer")} />
            </FormContent>
            <FormContent title="추가설명">
              <Input {...register("itemDesc")} />
            </FormContent>
            <FormContent title="판매유형">
              <MSelect selectList={selectList.boxSalesType} placeholder="" name="boxSales" control={control} />
            </FormContent>
            <FormContent title="랭킹 노출">
              <MSelect selectList={selectList.rankingType} placeholder="" name="disableRank" control={control} />
            </FormContent>
            <FormContent title="추천 주류/설명">
              <FormSwitchWrapper>
                <FormSwitchTitle>추천 등록</FormSwitchTitle>
                <MCheckBox
                  name="recommend"
                  onChange={value => {
                    if (value === false) {
                      setValue("recommendText", null);
                    }
                  }}
                  control={control}
                />
              </FormSwitchWrapper>
              <Input style={{ flex: 4 }} disabled={!getValues("recommend")} {...register("recommendText")} />
            </FormContent>
            <FormContent title="판매수량">
              <FormSwitchWrapper>
                <FormSwitchTitle>수량 제한</FormSwitchTitle>
                <MCheckBox name="countLimit" control={control} />
              </FormSwitchWrapper>
              <Input style={{ flex: 4 }} disabled={!getValues("countLimit")} {...register("count")} type={"number"} />
            </FormContent>
            <FormContent title="주문제한">
              <Input {...register("orderCount")} type={"number"} />
            </FormContent>
            <FormContent title="강제노출/설명">
              <FormSwitchWrapper>
                <FormSwitchTitle>강제노출</FormSwitchTitle>
                <MCheckBox
                  name="forceShow"
                  onChange={value => {
                    if (value === false) {
                      setValue("forceShowText", null);
                    }
                  }}
                  control={control}
                />
              </FormSwitchWrapper>
              <Input style={{ flex: 4 }} disabled={!getValues("forceShow")} {...register("forceShowText")} />
            </FormContent>

            <FormTitleWrapper>
              <FormTitle>보나뱅크 품목</FormTitle>
              <FormButtonWrapper>
                <Button
                  btType="default"
                  // containerStyle={{ padding: "20px 0", display: "flex" }}
                  // style={{ flex: "1", backgroundColor: "#3c3cDD", color: "white", borderRadius: "30px" }}
                  type="button"
                  title="+ 추가"
                  onClick={() => append({ id: undefined, value: undefined, label: undefined })}
                />
              </FormButtonWrapper>
            </FormTitleWrapper>
            {fields.map((item, index) => {
              return (
                <FormContent key={item.id} title="보나뱅크 품목">
                  <MSelectSearch
                    key={`search_${item.id}`}
                    control={control}
                    name={`item_has_companies.${index}`}
                    apiPath={"/api/bonaItem?"}
                    placeholder={"보나코드 또는 이름"}
                    generateOption={bonaGenerationOption}
                  />
                  <Button style={{ backgroundColor: "red" }} type="button" title="제거" onClick={() => remove(index)} />
                </FormContent>
              );
            })}
          </FormSection>
          <FormSection>
            <FormTitleWrapper>
              <FormTitle></FormTitle>
              <FormButtonWrapper>
                {item && <Button onClick={deleteItemFn} danger style={{ padding: "0 50px" }} type={"button"} title={"삭제"} />}
                <Button style={{ padding: "0 50px" }} type={"submit"} title={"저장"} />
              </FormButtonWrapper>
            </FormTitleWrapper>
          </FormSection>
        </Form>
      </FormInnerWrapper>
    </FormWrapper>
  );
};

export default ItemForm;
