import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Table from "../../components/table";
import InputFilter from "../../components/inputFilter";
import useNotificationStore from "../../stores/notification";
import moment from "moment";
import Button from "../../components/button";
import useAuthStore from "../../stores/auth";
import NotificationForm from "../form/notificationForm";
import { toast } from "react-toastify";
import Label from "../../components/Label";
import SelectFilter from "../../components/selectFilter";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 24px;
  flex: 1;
  overflow: hidden;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  border: 1px solid #f0f0f0;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const SearchWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  border: 1px solid #f0f0f0;
  border-top: 0px;
`;
const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  padding: 10px 24px;
  justify-content: space-between;
  border: 1px solid #f0f0f0;
  border-top: 0px;
`;
const TableHeader = styled.div`
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TableHeaderButtonWrapper = styled.div``;
const NotificationPage = () => {
  const {
    notifications,
    totalLength,
    addNotification,
    deleteNotification,
    setTotalLength,
    search,
    type,
    setType,
    setSearch,
    setNotificationType,
    resetNotificationStore,
    notificationTypes
  } = useNotificationStore();
  const [selectNotification, setSelectNotification] = useState<TNotification>();
  const { apiFetch } = useAuthStore();

  useEffect(() => {
    apiFetch("/api/notification/type", "GET").then(data => {
      const types = _.map(data, notificationType => ({ value: notificationType.id, label: notificationType.name }));
      setNotificationType(types);
    });
    return resetNotificationStore;
  }, []);

  useEffect(() => {
    if (notifications.length === 0) update();
  }, [search, type]);

  const limit = 50;
  const columns = useMemo(
    () => [
      {
        title: "번호",
        dataIndex: "id",
        key: "id",
        width: 150
      },
      {
        title: "제목",
        dataIndex: "title",
        key: "title",
        className: "min-width-table table-headline",
        ellipsis: true
      },
      {
        title: "알림타입",
        dataIndex: "notificationType",
        key: "notificationType",
        width: 120,
        render: (_, { notificationType }) => <Label value={"N"} title={notificationType.name} />
      },
      {
        title: "등록일",
        width: 200,
        render: (row: any) => moment(row.created_at).format("YYYY-MM-DD HH:mm")
      },
      {
        title: "수정일",
        width: 200,
        render: (row: any) => moment(row.updated_at).format("YYYY-MM-DD HH:mm")
      }
    ],
    []
  );

  const update = () => {
    const offset = notifications.length;
    let apiPath = `/api/notification?limit=${limit}&offset=${offset}&search=${search}&type=${type}`;
    apiFetch(apiPath, "GET").then(data => {
      addNotification(data[0]);
      setTotalLength(data[1]);
    });
  };
  const onClickFn = (row: any) => {
    setSelectNotification(row);
  };

  const onChangeFilter = (value: string) => {
    setSearch(value);
  };

  const resetNotice = () => {
    setSelectNotification(undefined);
  };

  const deleteNotificationFn = () => {
    if (selectNotification) {
      apiFetch(`api/notification`, "DELETE", { id: selectNotification.id }).then(data => {
        deleteNotification(selectNotification.id);
        setSelectNotification(undefined);
        toast("삭제되었습니다.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        });
      });
    }
  };

  const onChangeType = (value: string) => {
    setType(value);
  };

  return (
    <PageWrapper>
      <ContentWrapper>
        <SearchWrapper>
          <InputFilter onChange={onChangeFilter} search={search} label="알림 검색" placeHolder="제목 및 내용" />
          <SelectFilter
            selectList={[{ label: "전체", value: "" }, ...notificationTypes]}
            onChange={onChangeType}
            label={"알림타입"}
            selectValue={type}
            width={200}
          />
        </SearchWrapper>
      </ContentWrapper>
      <TableHeaderWrapper>
        <TableHeader>{`알림 정보 (총 ${totalLength}개)`}</TableHeader>
        <TableHeaderButtonWrapper>
          <Button title="+ 신규알림 생성" onClick={resetNotice} />
        </TableHeaderButtonWrapper>
      </TableHeaderWrapper>
      <ContentWrapper style={{ overflow: "scroll" }}>
        <Table selectItem={selectNotification} totalCount={totalLength} columns={columns} data={notifications} update={update} onClick={onClickFn} />
        <NotificationForm
          deleteNotificationFn={deleteNotificationFn}
          notification={selectNotification}
          submit={notification => {
            setSelectNotification(notification);
          }}
        />
      </ContentWrapper>
    </PageWrapper>
  );
};

export default NotificationPage;
