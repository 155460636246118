import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import MSelect from "../../components/formSelect";
import MSelectSearch from "../../components/formSelectSearch";
import useValueStore from "../../stores/values";
import MCheckBox from "../../components/fromCheckbox";
import useBonaItemStore from "../../stores/bonaItem";
import useAuthStore from "../../stores/auth";
import Button from "../../components/button";
import MImage from "../../components/formImage";
import { toast } from "react-toastify";
import FormContent from "../../components/formContent";

interface BonaItemType {
  id: number;
  itemKoName: string;
  itemVolume: number;
  itemInCount: number;
  itemBuyer: string;
}
const FormWrapper = styled.div`
  display: flex;
  flex: 1 1 0%;
  overflow: hidden;
  padding: 20px;
`;
const FormInnerWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow-y: scroll;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: fit-content;
`;

const FormSection = styled.div`
  padding: 8px 24px;
`;
const FormTitleWrapper = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const FormTitle = styled.div`
  font-size: 18px;
`;
const FormButtonWrapper = styled.div``;
const FormSwitchWrapper = styled.div<{ borderNone?: boolean }>`
  display: flex;
  ${props => (props.borderNone ? "" : "border: 1px solid #f0f0f0;")}
  flex: 1;
  align-items: center;
  justify-content: space-around;
`;
const FormSwitchTitle = styled.div``;
const FormDesc = styled.div`
  display: flex;
  align-items: center;
`;
const Input = styled.input`
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 0 10px;
`;
const BonaForm = (props: { item?: any | undefined }) => {
  const { item } = props;
  const [isFetch, setIsFetch] = useState<boolean>(false);
  const { bonaType, itemTypes, selectList } = useValueStore();
  const { updateItem } = useBonaItemStore();
  const { apiFetch, uploadFileApi } = useAuthStore();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    getValues,
    watch
  } = useForm({
    defaultValues: item
      ? item
      : {
          item: null,
          create_new_item: false,
          new_item: null
        }
  });

  watch("create_new_item");
  watch("new_item.countLimit");
  watch("new_item.recommend");
  watch("item");

  useEffect(() => {
    if (getValues("create_new_item")) {
      setValue("item", null);
      setValue("new_item", {
        itemKoName: getValues("item_name"),
        itemVolume: getValues("item_volume"),
        itemBuyer: getValues("item_buyer"),
        recommend: false,
        countLimit: false
      });
    } else {
      setValue("new_item", null);
    }
  }, [getValues("create_new_item")]);

  const submit = handleSubmit(sendData => {
    if (isFetch) {
      return;
    }
    setIsFetch(true);
    if (item) {
      if (sendData.new_item) {
        if (sendData.new_item.countLimit && sendData.new_item.count === null) {
          sendData.new_item.count = 0;
        }
        if (!sendData.new_item.recommend && sendData.new_item.recommendText !== null) {
          sendData.new_item.recommendText = "";
        }
      }
      apiFetch(`/api/bona/item`, "PUT", sendData)
        .then(data => {
          updateItem(data);
          toast("저장되었습니다.", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          });
        })
        .catch(err => {
          toast(err.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          });
        });
    }
    setIsFetch(false);
  });

  const bonaGenerationOption = (option: BonaItemType) => {
    return {
      value: option.id,
      label: option.itemKoName ? `${option.itemKoName}/${option.itemVolume}ml/${option.itemBuyer}` : ""
    };
  };

  const imageUpload = (e: any) => {
    uploadFileApi("/api/itemImageUpload", e.target.files).then(data => {
      let imagePath = data.url;
      if (data.url.indexOf("/Users/test/zugo-server-nodejs/public/uploads/") == 0) {
        imagePath = data.url.replace("/Users/test/zugo-server-nodejs/public/uploads/", "");
      }

      setValue("new_item.imagePath", imagePath);
    });
  };

  useEffect(() => {
    reset(item);
  }, [item]);

  return (
    <FormWrapper>
      <FormInnerWrapper>
        <Form onSubmit={submit}>
          <FormSection>
            <FormTitleWrapper>
              <FormTitle>보나뱅크 정보</FormTitle>
              <FormButtonWrapper>
                <FormSwitchWrapper borderNone>
                  <FormSwitchTitle>미사용여부</FormSwitchTitle>
                  <MCheckBox disabled={!item} name="show_disable" control={control} />
                </FormSwitchWrapper>
              </FormButtonWrapper>
            </FormTitleWrapper>
            <FormContent title="보나뱅크코드">
              <FormDesc>{item?.bona_item_code}</FormDesc>
            </FormContent>
            <FormContent title="이름">
              <FormDesc>{item?.item_name}</FormDesc>
            </FormContent>
            <FormContent title="용량(ml)">
              <FormDesc>{item?.item_volume}</FormDesc>
            </FormContent>
            <FormContent title="입수량">
              <FormDesc>{item?.item_in_count}</FormDesc>
            </FormContent>
            <FormContent title="수입제조사">
              <FormDesc>{item?.item_buyer}</FormDesc>
            </FormContent>
            <FormContent title="타입">
              <MSelect selectList={bonaType} placeholder="선택해주세요" control={control} name="type" />
            </FormContent>
            <FormContent title="판매타입">
              <FormDesc>{item?.item_use}</FormDesc>
            </FormContent>
            <FormContent title="상태">
              <FormDesc>{item?.item_state}</FormDesc>
            </FormContent>
          </FormSection>
          <FormSection>
            <FormTitleWrapper>
              <FormTitle>연결된 품목</FormTitle>
              <FormButtonWrapper>
                <FormSwitchWrapper borderNone>
                  <FormSwitchTitle>신규 생성</FormSwitchTitle>
                  <MCheckBox disabled={!item} name="create_new_item" control={control} onChange={value => setValue("new_item", value)} />
                </FormSwitchWrapper>
              </FormButtonWrapper>
            </FormTitleWrapper>
            {!getValues("create_new_item") && (
              <FormContent title="앱 품목명">
                <MSelectSearch
                  control={control}
                  name={`item`}
                  apiPath={"/api/getItem?"}
                  placeholder={"주고 품목명"}
                  generateOption={bonaGenerationOption}
                  disabled={!item || getValues("new_item")}
                />
              </FormContent>
            )}
            {getValues("create_new_item") && (
              <>
                <MImage name="new_item.imagePath" control={control} onChange={imageUpload} />
                <FormTitleWrapper>
                  <FormTitle>기본정보</FormTitle>
                </FormTitleWrapper>
                <FormContent title="이름">
                  <Input {...register("new_item.itemKoName", { required: true, validate: (value, formValues) => value !== "" })} />
                </FormContent>
                <FormContent title="영문명">
                  <Input {...register("new_item.itemEnName")} />
                </FormContent>
                <FormContent title="용량(ml)">
                  <Input {...register("new_item.itemVolume", { required: true })} />
                </FormContent>
                <FormContent title="주류 종류">
                  <MSelect selectList={itemTypes} placeholder="선택해주세요" control={control} name="new_item.itemType.id" />
                </FormContent>
                <FormContent title="수입제조사">
                  <Input {...register("itemBuyer")} />
                </FormContent>
                <FormContent title="판매유형">
                  <MSelect selectList={selectList.boxSalesType} placeholder="" name="new_item.boxSales" control={control} />
                </FormContent>
                <FormContent title="랭킹 노출">
                  <MSelect selectList={selectList.rankingType} placeholder="" name="new_item.disableRank" control={control} />
                </FormContent>
                <FormContent title="추천 주류/설명">
                  <FormSwitchWrapper>
                    <FormSwitchTitle>추천 등록</FormSwitchTitle>
                    <MCheckBox
                      name="new_item.recommend"
                      onChange={value => {
                        if (value === false) {
                          setValue("recommendText", null);
                        }
                      }}
                      control={control}
                    />
                  </FormSwitchWrapper>
                  <Input style={{ flex: 4 }} disabled={!getValues("new_item.recommend")} {...register("new_item.recommendText")} />
                </FormContent>
                <FormContent title="판매수량">
                  <FormSwitchWrapper>
                    <FormSwitchTitle>수량 제한</FormSwitchTitle>
                    <MCheckBox name="new_item.countLimit" control={control} />
                  </FormSwitchWrapper>
                  <Input style={{ flex: 4 }} disabled={!getValues("new_item.countLimit")} {...register("new_item.count")} type={"number"} />
                </FormContent>
                <FormContent title="주문제한">
                  <Input {...register("new_item.orderCount")} type={"number"} />
                </FormContent>
              </>
            )}
          </FormSection>
          <FormSection>
            <FormTitleWrapper>
              <FormTitle></FormTitle>
              <FormButtonWrapper>
                <Button style={{ padding: "0 50px" }} type={"submit"} title={"저장"} />
              </FormButtonWrapper>
            </FormTitleWrapper>
          </FormSection>
        </Form>
      </FormInnerWrapper>
    </FormWrapper>
  );
};

export default BonaForm;
