import React, { useEffect } from "react";
import { Route, Routes, Outlet, Link, useLocation } from "react-router-dom";
import useAuthStore from "../stores/auth";
import ItemPage from "./page/item";
import LoginPage from "./page/login";
import BonaItemPage from "./page/bonaItem";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import CustomerItemPage from "./page/customerItem";
import NoticePage from "./page/notice";
import NotificationPage from "./page/notification";
import SystemPage from "./page/system";
import UserPage from "./page/user";
import { Button } from "antd";
import HolidayPage from "./page/holiday";
import FranchiseePage from "./page/franchisee";
import FranchiseeUserPage from "./page/franchiseeUser";
import ItemComplaintPage from "./page/itemComplaint";

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: rgba(247, 248, 250, 1);
`;

const HeaderWrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #fff;
`;

const MenuList = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
`;

const MenuItem = styled.li`
  list-style: none;
  display: flex;
  font-size: 20px;
  justify-content: center;
  align-items: center;
`;

const MenuContent = styled(Link)<{ select?: boolean }>`
  flex: 1;
  text-decoration: none;
  padding: 10px 20px;
  ${props =>
    props.select ? "color:rgba(22, 119, 255, 1); border-bottom: 2px solid rgba(22, 119, 255, 1);" : "color:#000; border-bottom: 2px solid #FFF0;"}
`;

const MenuLogout = styled.div`
  flex: 1;
  text-decoration: none;
  color: #000;
  cursor: pointer;
`;
const MenuLogo = styled.img`
  width: 130px;
  height: 24px;
  object-fit: contain;
`;

export default function App() {
  const { isLogin, setLogin } = useAuthStore();
  const id_token = localStorage.getItem("id_token");
  useEffect(() => {
    if (id_token && id_token != "") {
      setLogin(true, id_token);
    } else {
      setLogin(false, "");
    }
  }, [id_token]);
  return (
    <>
      {isLogin ? (
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="" element={<ItemPage />} />
            <Route path="bonaItem" element={<BonaItemPage />} />
            <Route path="customerItem" element={<CustomerItemPage />} />
            {/* <Route path="notice" element={<NoticePage />} /> */}
            <Route path="notification" element={<NotificationPage />} />
            <Route path="system" element={<SystemPage />} />
            <Route path="user" element={<UserPage />} />
            <Route path="holiday" element={<HolidayPage />} />
            <Route path="franchisee" element={<FranchiseePage />} />
            <Route path="franchiseeUser" element={<FranchiseeUserPage />} />
            <Route path="itemComplaint" element={<ItemComplaintPage />} />
          </Route>
          <Route path="*" element={<NoMatch />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="*" element={<LoginPage />} />
        </Routes>
      )}
      <ToastContainer />
    </>
  );
}

function Layout() {
  const { logout } = useAuthStore();
  const { pathname } = useLocation();
  return (
    <MainWrapper>
      <HeaderWrapper>
        <MenuList style={{ paddingLeft: "14px", alignItems: "center" }}>
          <MenuLogo src="https://d17mmzdc0h66bb.cloudfront.net/image/logo.png" />
        </MenuList>
        <MenuList
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {/* <MenuItem>
            <MenuContent to="/">홈</MenuContent>
          </MenuItem> */}
          <MenuItem>
            <MenuContent select={pathname === "/"} to="/">
              품목
            </MenuContent>
          </MenuItem>
          <MenuItem>
            <MenuContent select={pathname === "/bonaItem"} to="/bonaItem">
              보나뱅크품목
            </MenuContent>
          </MenuItem>
          <MenuItem>
            <MenuContent select={pathname === "/customerItem"} to="/customerItem">
              거래처별 품목
            </MenuContent>
          </MenuItem>
          {/* <MenuItem>
            <MenuContent to="/notice">공지사항</MenuContent>
          </MenuItem> */}
          <MenuItem>
            <MenuContent select={pathname === "/notification"} to="/notification">
              알림
            </MenuContent>
          </MenuItem>
          <MenuItem>
            <MenuContent select={pathname === "/user"} to="/user">
              관리자설정
            </MenuContent>
          </MenuItem>
          <MenuItem>
            <MenuContent select={pathname === "/holiday"} to="/holiday">
              휴일설정
            </MenuContent>
          </MenuItem>
          <MenuItem>
            <MenuContent select={pathname === "/itemComplaint"} to="/itemComplaint">
              품목문의
            </MenuContent>
          </MenuItem>
          <MenuItem>
            <MenuContent select={pathname === "/franchisee"} to="/franchisee">
              프랜차이즈
            </MenuContent>
          </MenuItem>
          <MenuItem>
            <MenuContent select={pathname === "/franchiseeUser"} to="/franchiseeUser">
              프랜차이즈 유저
            </MenuContent>
          </MenuItem>
          {/* <MenuItem>
            <MenuContent to="/system">시스템설정</MenuContent>
          </MenuItem> */}
        </MenuList>

        <MenuList>
          <MenuItem>
            <Button onClick={logout}>로그아웃</Button>
          </MenuItem>
        </MenuList>
      </HeaderWrapper>
      <Outlet />
    </MainWrapper>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
