import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import MCheckBox from "../../components/fromCheckbox";
import Button from "../../components/button";
import useAuthStore from "../../stores/auth";
import { toast } from "react-toastify";
import FormContent from "../../components/formContent";
import MMultiDatePicker from "../../components/fromMultiDatePicker";
import useHolidayStore from "../../stores/holiday";

const FormWrapper = styled.div`
  display: flex;
  flex: 1 1 0%;
  overflow: hidden;
  padding: 20px;
`;
const FormInnerWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow-y: scroll;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: fit-content;
`;

const FormSection = styled.div`
  padding: 8px 24px;
`;
const FormTitleWrapper = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const FormTitle = styled.div`
  font-size: 18px;
`;
const FormSwitchWrapper = styled.div<{ borderNone?: boolean }>`
  display: flex;
  ${props => (props.borderNone ? "" : "border: 1px solid #f0f0f0;")}
  flex: 1;
  align-items: center;
  justify-content: space-around;
`;
const FormSwitchTitle = styled.div``;
const FormButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
`;
const Input = styled.input`
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 0 10px;
`;
const HolidayForm = (props: { holiday?: any | undefined; resetHoliday: () => void }) => {
  const { holiday, resetHoliday } = props;
  const [isFetch, setIsFetch] = useState<boolean>(false);
  const { updateHoliday, deleteHoliday, resetHolidayStore } = useHolidayStore();
  const { apiFetch, uploadFileApi } = useAuthStore();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: holiday
      ? holiday
      : {
          reason: "",
          useYn: true
        }
  });

  const submit = handleSubmit(sendData => {
    if (isFetch) {
      return;
    }
    setIsFetch(true);
    if (sendData.date === undefined || sendData.date.length <= 0) {
      toast("날짜를 선택해주세요.", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
      setIsFetch(false);
      return;
    }
    if (sendData.id) {
      apiFetch(`/api/holiday/${sendData.id}`, "PUT", sendData)
        .then(data => {
          updateHoliday(data);
          toast("저장되었습니다.", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          });
        })
        .catch(err => {
          toast(err.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          });
        });
    } else {
      apiFetch(`/api/holiday`, "POST", sendData)
        .then(data => {
          resetHolidayStore();
          resetHoliday();
          reset({
            reason: "",
            useYn: true
          });
          toast("저장되었습니다.", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          });
        })
        .catch(err => {
          toast(err.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          });
        });
    }
    setIsFetch(false);
  });

  const deleteHolidayFn = () => {
    apiFetch(`/api/holiday/${holiday.id}`, "DELETE")
      .then(() => {
        deleteHoliday(holiday);
        resetHoliday();
        toast("삭제되었습니다.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        });
      })
      .catch(err => {
        toast(err.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        });
      });
  };

  useEffect(() => {
    if (!holiday) {
      reset({
        reason: "",
        useYn: true
      });
    } else {
      reset(holiday);
    }
  }, [holiday]);

  return (
    <FormWrapper>
      <FormInnerWrapper>
        <Form onSubmit={submit}>
          <FormSection>
            <FormTitleWrapper>
              <FormTitle>기본정보</FormTitle>
              <FormButtonWrapper>
                <FormSwitchWrapper borderNone>
                  <FormSwitchTitle>사용여부</FormSwitchTitle>
                  <MCheckBox disabled={!holiday} name="useYn" control={control} />
                </FormSwitchWrapper>
              </FormButtonWrapper>
            </FormTitleWrapper>
            <FormContent title="날짜">
              <MMultiDatePicker disabled={holiday} name={"date"} control={control} />
            </FormContent>
            <FormContent title="사유">
              <Input {...register("reason", { required: true, validate: (value, formValues) => value !== "" })} />
            </FormContent>
          </FormSection>
          <FormSection>
            <FormTitleWrapper>
              <FormTitle></FormTitle>
              <FormButtonWrapper>
                {holiday && <Button onClick={deleteHolidayFn} danger style={{ padding: "0 50px" }} type={"button"} title={"삭제"} />}
                <Button style={{ padding: "0 50px" }} type={"submit"} title={"저장"} />
              </FormButtonWrapper>
            </FormTitleWrapper>
          </FormSection>
        </Form>
      </FormInnerWrapper>
    </FormWrapper>
  );
};

export default HolidayForm;
