import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Table from "../../components/table";
import InputFilter from "../../components/inputFilter";
import useValueStore from "../../stores/values";
import SelectFilter from "../../components/selectFilter";
import Button from "../../components/button";
import useAuthStore from "../../stores/auth";
import { ColumnsType } from "antd/es/table";
import useFranchiseeStore from "../../stores/franchisee";
import FranchiseeForm from "../form/franchiseeForm";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 24px;
  flex: 1;
  overflow: hidden;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  border: 1px solid #f0f0f0;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const SearchWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  border: 1px solid #f0f0f0;
  border-top: 0px;
`;
const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  padding: 10px 24px;
  justify-content: space-between;
  border: 1px solid #f0f0f0;
  border-top: 0px;
`;
const TableHeader = styled.div`
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TableHeaderButtonWrapper = styled.div``;

const FranchiseePage = () => {
  const { franchisees, totalLength, addFranchisee, setSearch, setUse, setTotalLength, setType, use, type, search, resetFranchiseeStore } =
    useFranchiseeStore();
  const { selectList } = useValueStore();
  const [selectFranchisee, setSelectFranchisee] = useState();
  const { apiFetch } = useAuthStore();

  useEffect(() => {
    return resetFranchiseeStore;
  }, []);

  useEffect(() => {
    if (franchisees.length === 0) update();
  }, [search, use, type]);

  const limit = 50;
  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: "번호",
        dataIndex: "id",
        key: "id",
        width: 80
      },
      {
        title: "이름",
        dataIndex: "name",
        key: "name",
        className: "min-width-table table-headline",
        ellipsis: true
      },
      {
        title: "판매타입",
        dataIndex: "use",
        key: "use",
        width: 80
      },
      {
        title: "타입",
        width: 90,
        render: (_, { type }) => {
          return type === "대형" ? "직영형" : type === "소형" ? "가맹형" : "일반형";
        }
      },
      {
        title: "매장갯수",
        dataIndex: "count",
        key: "count",
        width: 90
      }
    ],
    []
  );

  const data = useMemo(() => franchisees, [franchisees]);
  const update = () => {
    const offset = franchisees.length;
    let apiPath = `/api/franchisee?limit=${limit}&offset=${offset}&search=${search}&use=${use}&type=${type}`;
    apiFetch(apiPath, "GET").then(data => {
      addFranchisee(data.data);
      setTotalLength(data.count);
    });
  };
  const onClickFn = (row: any) => {
    apiFetch(`/api/franchisee/${row.id}`, "GET").then(data => {
      setSelectFranchisee(data.data);
    });
  };

  const onChangeFilter = (value: string) => {
    setSearch(value);
  };

  const onChangeType = (value: string) => {
    setType(value);
  };

  const onChangeUse = (value: string) => {
    setUse(value);
  };

  const newItem = () => {
    setSelectFranchisee(undefined);
  };

  return (
    <PageWrapper>
      <ContentWrapper>
        <SearchWrapper>
          <InputFilter onChange={onChangeFilter} search={search} placeHolder="이름" label="이름 검색" />
          <SelectFilter
            selectList={[{ label: "전체", value: "" }, ...selectList.franchiseeUses]}
            onChange={onChangeUse}
            label={"판매유형"}
            selectValue={use}
            width={200}
          />
          <SelectFilter
            selectList={[{ label: "전체", value: "" }, ...selectList.franchiseeTypes]}
            onChange={onChangeType}
            label={"판매타입"}
            selectValue={type}
            width={200}
          />
        </SearchWrapper>
      </ContentWrapper>
      <TableHeaderWrapper>
        <TableHeader>{`프랜차이즈 정보 (총 ${totalLength}개)`}</TableHeader>
        <TableHeaderButtonWrapper>
          <Button title="+ 신규 프랜차이즈 생성" onClick={newItem} />
        </TableHeaderButtonWrapper>
      </TableHeaderWrapper>
      <ContentWrapper style={{ overflow: "scroll" }}>
        <Table selectItem={selectFranchisee} totalCount={totalLength} columns={columns} data={franchisees} update={update} onClick={onClickFn} />
        <FranchiseeForm franchisee={selectFranchisee} onEnd={newItem} />
      </ContentWrapper>
    </PageWrapper>
  );
};

export default FranchiseePage;
