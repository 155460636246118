import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Table from "../../components/table";
import InputFilter from "../../components/inputFilter";
import useUserStore from "../../stores/user";
import moment from "moment";
import UserForm from "../form/userForm";
import Button from "../../components/button";
import useAuthStore from "../../stores/auth";
import useValueStore from "../../stores/values";
import RadioFilter from "../../components/radioFilter";
import Label from "../../components/Label";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 24px;
  flex: 1;
  overflow: hidden;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  border: 1px solid #f0f0f0;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const SearchWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  border: 1px solid #f0f0f0;
  border-top: 0px;
`;
const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  padding: 10px 24px;
  justify-content: space-between;
  border: 1px solid #f0f0f0;
  border-top: 0px;
`;
const TableHeader = styled.div`
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TableHeaderButtonWrapper = styled.div``;

const UserPage = () => {
  const { users, totalLength, addUser, userState, setUserState, setTotalLength, search, setSearch, resetUserStore } = useUserStore();
  const { filterList } = useValueStore();
  const [selectUser, setSelectUser] = useState();
  const { apiFetch } = useAuthStore();
  useEffect(() => {
    return resetUserStore;
  }, []);
  useEffect(() => {
    if (users.length === 0) update();
  }, [search, userState]);

  const limit = 50;
  const columns = useMemo(
    () => [
      {
        title: "아이디",
        dataIndex: "id",
        key: "id",
        className: "table-headline",
        width: 150
      },
      {
        title: "이름",
        dataIndex: "userName",
        key: "userName",
        width: 100
      },
      {
        title: "사용",
        dataIndex: "state",
        key: "state",
        width: 80,
        render: (_, { state }) => <Label value={state ? "N" : "R"} title={state ? "사용" : "미사용"} />
      },
      {
        title: "등록일",
        dataIndex: "created_at",
        key: "created_at",
        width: 200,
        render: (_, { created_at }) => moment(created_at).format("YYYY-MM-DD HH:mm")
      },
      {
        title: "수정일",
        dataIndex: "updated_at",
        key: "updated_at",
        width: 200,
        render: (_, { updated_at }) => moment(updated_at).format("YYYY-MM-DD HH:mm")
      }
    ],
    []
  );

  const update = () => {
    const offset = users.length;
    let apiPath = `/api/user?limit=${limit}&offset=${offset}&search=${search}&state=${userState}`;
    apiFetch(apiPath, "GET").then(data => {
      addUser(data[0]);
      setTotalLength(data[1]);
    });
  };
  const onClickFn = (row: any) => {
    setSelectUser(row);
  };

  const onChangeFilter = (value: string) => {
    setSearch(value);
  };

  const resetUser = () => {
    setSelectUser(undefined);
  };

  const onChangeState = (value: string) => {
    setUserState(value);
  };

  return (
    <PageWrapper>
      <ContentWrapper>
        <SearchWrapper>
          <InputFilter onChange={onChangeFilter} search={search} label="관리자 검색" placeHolder="아이디" />
          <RadioFilter key={"filter1"} selectList={filterList.stateType} onChange={onChangeState} label={"사용 여부"} selectValue={userState} />
        </SearchWrapper>
      </ContentWrapper>
      <TableHeaderWrapper>
        <TableHeader>{`관리자 리스트 (총 ${totalLength}개)`}</TableHeader>
        <TableHeaderButtonWrapper>
          <Button title="+ 신규 관리자 생성" onClick={resetUser} />
        </TableHeaderButtonWrapper>
      </TableHeaderWrapper>
      <ContentWrapper>
        <Table selectItem={selectUser} totalCount={totalLength} columns={columns} data={users} update={update} onClick={onClickFn} />
        <UserForm
          user={selectUser}
          submit={user => {
            setSelectUser(user);
          }}
        />
      </ContentWrapper>
    </PageWrapper>
  );
};

export default UserPage;
