import React, { ReactNode, useEffect } from "react";
import { Radio } from "antd";
import styled from "styled-components";
export interface ISelectItem {
  label: ReactNode;
  value: string;
  selected?: boolean;
  disabled?: boolean;
  hidden?: boolean;
}

type CustomSelectProps<T> = {
  selectList: ISelectItem[];
  onChange?: (event: string) => void;
  valueChange?: (...param: any) => void;
  selectValue: any;
  label?: string;
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 12px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #00000073;
  margin-bottom: 4px;
`;

const RadioFilter = (props: CustomSelectProps<string>) => {
  const { label, selectValue, selectList, onChange, valueChange } = props;

  useEffect(() => {
    if (valueChange && selectValue) {
      valueChange(selectValue);
    }
  }, [selectValue]);

  return (
    <Container>
      <Title>{label}</Title>
      <Radio.Group
        size="large"
        defaultValue={selectValue}
        optionType="button"
        options={selectList}
        onChange={e => {
          const { value } = e.target;
          if (onChange) {
            onChange(value);
          }
        }}
      />
    </Container>
  );
};

export default RadioFilter;
