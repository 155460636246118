import React, { useState, SyntheticEvent } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeliveryEndForm from "../form/deliveryEndForm";
import styled from "styled-components";

const PageWrapper = styled.div`
  padding-top: 20px;
`;
const SystemPage = () => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <PageWrapper>
      <Accordion expanded={expanded === "timeSetting"} onChange={handleChange("timeSetting")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div>시간설정</div>
        </AccordionSummary>
        <AccordionDetails>
          <DeliveryEndForm />
        </AccordionDetails>
      </Accordion>
    </PageWrapper>
  );
};

export default SystemPage;
