import React, { useEffect, useState } from "react";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import useAuthStore from "../../stores/auth";

const FormWrapper = styled.form``;
const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const BottomWrapper = styled.div`
  justify-content: end;
  display: flex;
`;

const DeliveryEndForm = () => {
  const [deliveryEnd, setDeliveryEnd] = useState<Moment>(moment());
  const { handleSubmit } = useForm();
  const { apiFetch } = useAuthStore();
  useEffect(() => {
    apiFetch("/api/orderEnd", "GET").then(data => {
      setDeliveryEnd(moment(data.orderEnd));
    });
  }, []);

  const submit = handleSubmit(() => {
    apiFetch("/api/orderEnd", "PUT", { orderEnd: deliveryEnd.format("HH:mm") }).then(data => {
      setDeliveryEnd(moment(data.orderEnd));
    });
  });

  return (
    <FormWrapper onSubmit={submit}>
      <RowWrapper>
        <div>주문 마감시간</div>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <TimePicker
            onChange={(value: Moment | null) => {
              if (value) {
                setDeliveryEnd(value);
              }
            }}
            value={deliveryEnd}
            label={"HH"}
            ampm={false}
            views={["hours"]}
            timeSteps={{ hours: 1, minutes: 30 }}
            format={"HH"}
          />
        </LocalizationProvider>
      </RowWrapper>
      <BottomWrapper>
        <input type="submit" />
      </BottomWrapper>
    </FormWrapper>
  );
};

export default DeliveryEndForm;
