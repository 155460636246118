import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import useNotificationStore from "../../stores/notification";
import useAuthStore from "../../stores/auth";
import MSelectSearch from "../../components/formSelectSearch";
import MSelect from "../../components/formSelect";
import MCheckBox from "../../components/fromCheckbox";
import Button from "../../components/button";
import { toast } from "react-toastify";
import ToastEditor from "../../components/editor";
import ToastViewer from "../../components/viewer";
import FormContent from "../../components/formContent";

const FormWrapper = styled.div`
  display: flex;
  flex: 1 1 0%;
  overflow: hidden;
  padding: 20px;
`;
const FormInnerWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow-y: scroll;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: fit-content;
`;

const FormSection = styled.div`
  padding: 8px 24px;
`;
const FormTitleWrapper = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const FormTitle = styled.div`
  font-size: 18px;
`;
const FormButtonWrapper = styled.div``;
const FormSwitchWrapper = styled.div<{ borderNone?: boolean }>`
  display: flex;
  ${props => (props.borderNone ? "" : "border: 1px solid #f0f0f0;")}
  flex: 1;
  align-items: center;
  justify-content: space-around;
`;
const FormSwitchTitle = styled.div``;
const Input = styled.input`
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 0 10px;
`;

const NotificationForm = (props: {
  notification?: TNotification | undefined;
  submit: (notification: any) => void;
  deleteNotificationFn: () => void;
}) => {
  const { notification, deleteNotificationFn } = props;
  const ref = useRef<any>(null);
  const { updateNotification, notificationTypes } = useNotificationStore();
  const { apiFetch } = useAuthStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [isFetch, setIsFetch] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue
  } = useForm({
    defaultValues: notification
      ? notification
      : {
          title: "",
          body: "",
          item: null
        }
  });

  const submit = handleSubmit(sendData => {
    console.log("submit");
    if (isFetch) {
      console.log("isFetch", isFetch);
      return;
    }
    setIsFetch(true);
    const editorIns = ref?.current?.getInstance();
    // 에디터 작성 내용 markdown으로 저장
    let text = notification ? notification.text : null;
    try {
      text = editorIns.getHTML();
    } catch (e) {
      console.log("errer", e);
    }
    console.log(text);
    if (text === "<p><br></p>") {
      toast("내용을 입력하세요.1", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
      setIsFetch(false);
      return;
    } else if (!sendData.notificationType.id) {
      toast("알림타입을 입력하세요.", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
      setIsFetch(false);
      return;
    }
    const submitData = { ...sendData, text };
    console.log(submitData);
    if (notification) {
      apiFetch(`/api/notification`, "PUT", submitData).then(data => {
        updateNotification(data.notification);
        toast("재발송되었습니다.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        });
      });
    } else {
      apiFetch(`/api/notification`, "POST", submitData).then(data => {
        updateNotification(data.notification);
        props.submit(data.notification);
        toast("저장되었습니다.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        });
      });
    }
    setIsFetch(false);
  });
  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  }, [loading]);

  useEffect(() => {
    setLoading(true);
    if (!notification) {
      reset(
        {
          title: "",
          body: "",
          text: "",
          item: null
        },
        {
          keepDirtyValues: false,
          keepValues: false,
          keepDefaultValues: false,
          keepDirty: false
        }
      );
      editorReset();
    } else {
      reset(notification);
      editorReset(notification.text);
    }
  }, [notification]);

  const editorReset = (text?: string) => {
    const editorIns = ref?.current?.getInstance();
    if (editorIns) {
      if (!editorIns.isViewer()) {
        editorIns.reset();
        if (text) {
          editorIns.editorIns.setHTML(text);
        }
      }
    }
  };

  const bonaGenerationOption = (option: TItem) => {
    return {
      value: option.id,
      label: `${option.itemKoName}/${option.itemVolume}ml/${option.itemBuyer}`
    };
  };

  return (
    <FormWrapper>
      <FormInnerWrapper>
        <Form onSubmit={submit}>
          <FormSection>
            <FormTitleWrapper>
              <FormTitle>알림 정보</FormTitle>
              <FormButtonWrapper>
                <FormSwitchWrapper borderNone>
                  <FormSwitchTitle>VIP만 발송</FormSwitchTitle>
                  <MCheckBox name="is_vip" control={control} disabled={notification} />
                </FormSwitchWrapper>
              </FormButtonWrapper>
            </FormTitleWrapper>
            <FormContent title="알림 타입">
              <MSelect
                disabled={notification}
                selectList={notificationTypes}
                placeholder="선택해주세요"
                control={control}
                name="notificationType.id"
              />
            </FormContent>
            <FormContent title="제목">
              <Input disabled={notification} maxLength={10} required {...register("title")} />
            </FormContent>
            <FormContent title="간단설명">
              <Input disabled={notification} maxLength={40} required {...register("body")} />
            </FormContent>
            <FormContent title="내용" top>
              {!loading &&
                (notification ? (
                  <ToastViewer content={notification.text} editorRef={ref} />
                ) : (
                  <ToastEditor content={notification?.text} editorRef={ref} />
                ))}
            </FormContent>
            <FormContent title="품목">
              <MSelectSearch
                disabled={notification}
                noReset
                control={control}
                name={`item`}
                apiPath={"/api/getItem?"}
                placeholder={"주고 품목명"}
                generateOption={bonaGenerationOption}
              />
            </FormContent>
          </FormSection>
          <FormSection>
            <FormTitleWrapper>
              <FormTitle>{notification && <Button style={{ padding: "0 50px" }} danger title="삭제" onClick={deleteNotificationFn} />}</FormTitle>
              <FormButtonWrapper>
                <Button style={{ padding: "0 50px" }} type={"submit"} title={"알림 전송"} />
              </FormButtonWrapper>
            </FormTitleWrapper>
          </FormSection>
        </Form>
      </FormInnerWrapper>
    </FormWrapper>
  );
};

export default NotificationForm;
