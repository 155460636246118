import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Table from "../../components/table";
import InputFilter from "../../components/inputFilter";
import useValueStore from "../../stores/values";
import SelectFilter from "../../components/selectFilter";
import useCustomerItemStore from "../../stores/customerItem";
import CustomerItemForm from "../form/customerForm";
import useAuthStore from "../../stores/auth";
import Label from "../../components/Label";
import RadioFilter from "../../components/radioFilter";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 24px;
  flex: 1;
  overflow: hidden;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  border: 1px solid #f0f0f0;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const SearchWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  border: 1px solid #f0f0f0;
  border-top: 0px;
`;
const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  padding: 10px 24px;
  justify-content: space-between;
  border: 1px solid #f0f0f0;
  border-top: 0px;
  min-height: 40px;
`;
const TableHeader = styled.div`
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TableHeaderButtonWrapper = styled.div``;
const CustomerItemPage = () => {
  const {
    customerCommons,
    totalLength,
    addCustomerCommons,
    setTotalLength,
    search,
    setSearch,
    connectItem,
    setConnectItem,
    storeState,
    setStoreState,
    appUser,
    setAppUser,
    resetCustomerItemStore
  } = useCustomerItemStore();
  const [selectCustomerCommon, setSelectCustomerCommon] = useState();
  const { apiFetch } = useAuthStore();
  const { YNType, storeStateType, filterList } = useValueStore();

  useEffect(() => {
    return resetCustomerItemStore;
  }, []);
  useEffect(() => {
    if (customerCommons.length === 0) update();
  }, [search, connectItem, storeState, appUser]);

  const limit = 50;
  const columns = useMemo(
    () => [
      {
        title: "번호",
        dataIndex: "bona_id",
        key: "bona_id",
        width: 150
      },
      {
        title: "이름",
        dataIndex: "storeName",
        key: "storeName",
        className: "min-width-table table-headline",
        ellipsis: true
      },
      {
        title: "사업자번호",
        render: (_, { storeRegNumber }) => {
          return storeRegNumber ? (storeRegNumber.length >= 10 ? storeRegNumber.replace(/(\w{3})(\w{2})(\w{5})/g, "$1-$2-$3") : storeRegNumber) : "";
        }
      },
      {
        title: "상태",
        dataIndex: "state",
        key: "state",
        width: 100,
        render: (_, { storeState }) => {
          switch (storeState) {
            case "1":
              return <Label value={"N"} title={"정상"} />;
            case "2":
              return <Label value={"C"} title={"영업정지"} />;
            case "3":
              return <Label value={"G"} title={"휴업"} />;
            case "4":
              return <Label value={"R"} title={"폐업"} />;
            case "5":
              return <Label value={"GD"} title={"단절"} />;
            default:
              return <Label value={"N"} title={"알수없음"} />;
          }
        }
      },
      {
        title: "특수 품목",
        dataIndex: "customerItems",
        width: 100,
        render: (_, { customerItems }) => <Label value={customerItems.length > 0 ? "GB" : "N"} title={customerItems.length > 0 ? "등록" : "미등록"} />
      },
      {
        title: "앱사용자",
        dataIndex: "user_customers",
        width: 100,
        render: (_, { user_customers }) => (
          <Label value={user_customers.length > 0 ? "GB" : "N"} title={user_customers.length > 0 ? "등록" : "미등록"} />
        )
      }
    ],
    []
  );

  const update = () => {
    const offset = customerCommons.length;
    let apiPath = `/api/customer?limit=${limit}&offset=${offset}&search=${search}&connectItem=${connectItem}&state=${storeState}&appUser=${appUser}`;
    apiFetch(apiPath, "GET").then(data => {
      addCustomerCommons(data[0]);
      setTotalLength(data[1]);
    });
  };
  const onClickFn = (row: any) => {
    apiFetch(`/api/customer/${row.id}`, "GET").then(data => {
      setSelectCustomerCommon(data);
    });
  };

  const onChangeFilter = (value: string) => {
    setSearch(value);
  };

  const onChangeConnectItem = (value: string) => {
    setConnectItem(value);
  };

  const onChangeAppUser = (value: string) => {
    setAppUser(value);
  };

  const onChangeStoreState = (value: string) => {
    setStoreState(value);
  };

  return (
    <PageWrapper>
      <ContentWrapper>
        <SearchWrapper>
          <InputFilter onChange={onChangeFilter} search={search} label="거래처 검색" placeHolder="거래처명 또는 코드" />
          <RadioFilter selectList={filterList.registrationType} onChange={onChangeConnectItem} label={"특수 품목"} selectValue={connectItem} />
          <RadioFilter
            selectList={[{ value: "", label: "전체" }, ...storeStateType]}
            onChange={onChangeStoreState}
            label={"상태"}
            selectValue={storeState}
          />
          <RadioFilter selectList={filterList.registrationType} onChange={onChangeAppUser} label={"앱사용자"} selectValue={appUser} />
        </SearchWrapper>
      </ContentWrapper>
      <TableHeaderWrapper>
        <TableHeader>{`거래처 정보 (총 ${totalLength}개)`}</TableHeader>
        <TableHeaderButtonWrapper></TableHeaderButtonWrapper>
      </TableHeaderWrapper>
      <ContentWrapper style={{ overflow: "scroll" }}>
        <Table
          selectItem={selectCustomerCommon}
          totalCount={totalLength}
          columns={columns}
          data={customerCommons}
          update={update}
          onClick={onClickFn}
        />
        <CustomerItemForm customerCommon={selectCustomerCommon} />
      </ContentWrapper>
    </PageWrapper>
  );
};

export default CustomerItemPage;
