import _, { find } from "lodash";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import styled from "styled-components";
import MImage from "../../components/formImage";
import MSelect from "../../components/formSelect";
import MSelectSearch from "../../components/formSelectSearch";
import useValueStore from "../../stores/values";
import MCheckBox from "../../components/fromCheckbox";
import Button from "../../components/button";
import useAuthStore from "../../stores/auth";
import { toast } from "react-toastify";
import FormContent from "../../components/formContent";
import useFranchiseeStore from "../../stores/franchisee";

const FormWrapper = styled.div`
  display: flex;
  flex: 1 1 0%;
  overflow: hidden;
  padding: 20px;
`;
const FormInnerWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow-y: scroll;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: fit-content;
`;

const FormSection = styled.div`
  padding: 8px 24px;
`;
const FormTitleWrapper = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const FormTitle = styled.div`
  font-size: 18px;
`;
const FormButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
`;
const FormSwitchWrapper = styled.div`
  display: flex;
  border: 1px solid #f0f0f0;
  flex: 1;
  align-items: center;
  justify-content: space-around;
  min-width: 160px;
`;
const FormSwitchTitle = styled.div``;
const Input = styled.input`
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 0 10px;
`;
const FranchiseeForm = (props: { franchisee?: any; onEnd?: () => void }) => {
  const { franchisee } = props;
  const [isFetch, setIsFetch] = useState<boolean>(false);
  const { updateFranchisee, deleteFranchisee, addFranchisee } = useFranchiseeStore();
  const { selectList } = useValueStore();
  const { apiFetch, uploadFileApi } = useAuthStore();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    getValues,
    watch
  } = useForm<TFranchiseeItem>({
    defaultValues: franchisee
      ? franchisee
      : {
          franchisee_has_customers: []
        }
  });
  const { fields, append, remove } = useFieldArray<any, any, any>({
    control,
    name: "franchisee_has_customers"
  });

  watch("franchisee_has_customers");

  const getSendData = (sendData: any) => {
    return {
      id: sendData.id,
      name: sendData.name,
      franchisee_id: sendData.id,
      customer_ids: sendData.franchisee_has_customers.map((d: any) => d.value),
      use: sendData.use,
      type: sendData.type,
      url: sendData.url
    };
  };

  const imageUpload = (e: any) => {
    uploadFileApi("/api/franchiseeImageUpload", e.target.files).then(data => {
      let imagePath = data.url;
      if (data.url.indexOf("/Users/test/zugo-server-nodejs/public/uploads/") == 0) {
        imagePath = data.url.replace("/Users/test/zugo-server-nodejs/public/uploads/", "");
      }

      setValue("url", imagePath);
    });
  };

  const submit = handleSubmit(sendData => {
    if (isFetch) {
      return;
    }
    setIsFetch(true);
    if (!sendData.use && sendData.use !== null) {
      toast("매장유형을 입력해주새요", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
      setIsFetch(false);
      return;
    }
    if (!sendData.type && sendData.type !== null) {
      toast("매장타입을 입력해주새요", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
      setIsFetch(false);
      return;
    }
    if (sendData.id) {
      apiFetch(`/api/franchisee`, "PUT", getSendData(sendData))
        .then(data => {
          updateFranchisee({ ...data.result, count: data.result.franchisee_has_customers.length });
          toast("저장되었습니다.", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          });
        })
        .catch(err => {
          toast(err.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          });
        });
    } else {
      apiFetch(`/api/franchisee`, "POST", getSendData(sendData))
        .then(data => {
          addFranchisee([{ ...data.result, count: data.result.franchisee_has_customers.length }]);
          if (props.onEnd) {
            props.onEnd();
          }
          reset({
            id: undefined,
            name: "",
            use: "",
            type: "",
            franchisee_has_customers: []
          });
          toast("저장되었습니다.", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          });
        })
        .catch(err => {
          console.log(err);
          toast(err.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          });
        });
    }
    setIsFetch(false);
  });

  const customerGenerationOption = (option: TFranchiseeHasCustomer & { customer: TCustomerCommon }, search?: boolean) => {
    const lock = option.lock ? "품목잠금" : "품목미잠금";
    const storeState =
      option.customer && option.customer.storeState
        ? option.customer.storeState === "1"
          ? "정상"
          : option.customer.storeState === "2"
          ? "영업정지"
          : option.customer.storeState === "3"
          ? "휴업"
          : option.customer.storeState === "4"
          ? "폐업"
          : option.customer.storeState === "5"
          ? "단절"
          : ""
        : "";
    if (option.customer && (!search || !find(fields, { value: option.customer.id }))) {
      return {
        value: option.customer.id,
        label: option.customer.id ? `${option.customer.bona_id}/${option.customer.storeName}/${lock}/${storeState}` : ""
      };
    }
  };

  const deleteItemFn = () => {
    if (!franchisee) {
      return;
    }
    apiFetch(`/api/franchisee`, "DELETE", {
      franchisee_id: franchisee.id
    })
      .then(() => {
        deleteFranchisee(franchisee);
        reset({
          id: undefined,
          name: "",
          use: "",
          type: "",
          franchisee_has_customers: []
        });
        toast("삭제되었습니다.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        });
      })
      .catch(err => {
        toast(err.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        });
      });
  };

  useEffect(() => {
    if (!franchisee) {
      reset({
        name: "",
        use: "",
        type: "",
        franchisee_has_customers: []
      });
    } else {
      reset(franchisee);
    }
  }, [franchisee]);

  return (
    <FormWrapper>
      <FormInnerWrapper>
        <Form onSubmit={submit}>
          <FormSection>
            <FormTitleWrapper>
              <FormTitle>기본정보</FormTitle>
            </FormTitleWrapper>
            <MImage name="url" control={control} onChange={imageUpload} />
            <FormContent title="이름">
              <Input {...register("name", { required: true, validate: (value, formValues) => value !== "" })} />
            </FormContent>
            <FormContent title="매장유형">
              <MSelect selectList={selectList.franchiseeUses} placeholder="" name="use" control={control} />
            </FormContent>
            <FormContent title="매장타입">
              <MSelect selectList={selectList.franchiseeTypes} placeholder="" name="type" control={control} />
            </FormContent>
            <FormTitleWrapper
              style={{
                position: "sticky",
                background: "white",
                zIndex: 1,
                top: 0
              }}
            >
              <FormTitle>매장등록</FormTitle>
              <FormButtonWrapper>
                <Button
                  btType="default"
                  // containerStyle={{ padding: "20px 0", display: "flex" }}
                  // style={{ flex: "1", backgroundColor: "#3c3cDD", color: "white", borderRadius: "30px" }}
                  type="button"
                  title="+ 추가"
                  onClick={() => append({ id: undefined, label: undefined, value: undefined })}
                />
              </FormButtonWrapper>
            </FormTitleWrapper>
            {fields.map((fhc, index) => {
              console.log(fhc);
              if (fhc.customer === null) {
                return;
              }
              return (
                <FormContent key={`${fhc.id}/${index}`} title="매장">
                  <MSelectSearch
                    key={`search_${fhc.id}`}
                    control={control}
                    name={`franchisee_has_customers.${index}`}
                    apiPath={"/api/franchisee/search/customer?"}
                    placeholder={"매장코드 또는 이름"}
                    generateOption={customerGenerationOption}
                  />
                  <Button style={{ backgroundColor: "red" }} type="button" title="제거" onClick={() => remove(index)} />
                </FormContent>
              );
            })}
          </FormSection>
          <FormSection>
            <FormTitleWrapper>
              <FormTitle></FormTitle>
              <FormButtonWrapper>
                {franchisee && <Button onClick={deleteItemFn} danger style={{ padding: "0 50px" }} type={"button"} title={"삭제"} />}
                <Button style={{ padding: "0 50px" }} type={"submit"} title={"저장"} />
              </FormButtonWrapper>
            </FormTitleWrapper>
          </FormSection>
        </Form>
      </FormInnerWrapper>
    </FormWrapper>
  );
};

export default FranchiseeForm;
