import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Table from "../../components/table";
import useValueStore from "../../stores/values";
import useAuthStore from "../../stores/auth";
import { ColumnsType } from "antd/es/table";
import moment from "moment";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 24px;
  flex: 1;
  overflow: hidden;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  border: 1px solid #f0f0f0;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const SearchWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  border: 1px solid #f0f0f0;
  border-top: 0px;
`;
const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  padding: 10px 24px;
  justify-content: space-between;
  border: 1px solid #f0f0f0;
  border-top: 0px;
`;
const TableHeader = styled.div`
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ItemComplaintPage = () => {
  const { selectList } = useValueStore();
  const [itemComplaints, setItemComplaints] = useState<TItemComplaint[]>([]);
  const [totalLength, setTotalLength] = useState(0);
  const { apiFetch } = useAuthStore();

  useEffect(() => {
    update();
  }, []);

  const limit = 50;
  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: "번호",
        dataIndex: "id",
        key: "id",
        width: 80
      },
      {
        title: "내용",
        dataIndex: "complaint",
        key: "complaint",
        className: "min-width-table table-headline",
        ellipsis: false
      },
      {
        title: "품목",
        width: 400,
        render: (_, { item }) => item.id + "/" + item.itemKoName
      },
      {
        title: "작성일",
        render: (_, { created_at }) => moment(created_at).format("YYYY.MM.DD"),
        width: 150
      },
      {
        title: "작성자",
        render: (_, { user }) => user.id + "/" + user.name,
        width: 150
      },
      {
        title: "매장",
        render: (_, { user }) => user.user_customers[0]?.customerCommon?.storeName,
        width: 150
      }
    ],
    []
  );

  const update = () => {
    const offset = itemComplaints.length;
    let apiPath = `/api/itemComplaint?limit=${limit}&offset=${offset}`;
    apiFetch(apiPath, "GET").then(data => {
      setItemComplaints(prev => [...prev, ...data[0]]);
      setTotalLength(data[1]);
    });
  };

  return (
    <PageWrapper>
      <ContentWrapper>
        <SearchWrapper></SearchWrapper>
      </ContentWrapper>
      <TableHeaderWrapper>
        <TableHeader>{`품목문의 정보 (총 ${totalLength}개)`}</TableHeader>
      </TableHeaderWrapper>
      <ContentWrapper style={{ overflow: "scroll" }}>
        <Table totalCount={totalLength} columns={columns} data={itemComplaints} update={update} />
      </ContentWrapper>
    </PageWrapper>
  );
};

export default ItemComplaintPage;
