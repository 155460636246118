import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import ItemForm from "../form/itemForm";
import Table from "../../components/table";
import useItemStore from "../../stores/item";
import InputFilter from "../../components/inputFilter";
import useValueStore from "../../stores/values";
import SelectFilter from "../../components/selectFilter";
import Button from "../../components/button";
import useAuthStore from "../../stores/auth";
import { ColumnsType } from "antd/es/table";
import Label from "../../components/Label";
import RadioFilter from "../../components/radioFilter";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 24px;
  flex: 1;
  overflow: hidden;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  border: 1px solid #f0f0f0;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const SearchWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  border: 1px solid #f0f0f0;
  border-top: 0px;
`;
const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  padding: 10px 24px;
  justify-content: space-between;
  border: 1px solid #f0f0f0;
  border-top: 0px;
`;
const TableHeader = styled.div`
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TableHeaderButtonWrapper = styled.div``;

const ItemPage = () => {
  const {
    items,
    totalLength,
    addItem,
    setTotalLength,
    search,
    setSearch,
    boxSales,
    setBoxSales,
    disableRank,
    setDisableRank,
    type,
    setType,
    recommend,
    setRecommend,
    forceShow,
    setForceShow,
    resetItemStore
  } = useItemStore();
  const { setItemTypes, filterList, itemTypes } = useValueStore();
  const [selectItem, setSelectItem] = useState();
  const { apiFetch } = useAuthStore();

  useEffect(() => {
    apiFetch("/api/getItemType", "GET").then(itemTypes => {
      const types = _.map(itemTypes, itemType => ({ value: itemType.id, label: itemType.itemTypeName }));
      setItemTypes(types);
    });
    return resetItemStore;
  }, []);

  useEffect(() => {
    if (items.length === 0) update();
  }, [search, boxSales, disableRank, type, recommend, forceShow]);

  const limit = 50;
  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: "번호",
        dataIndex: "id",
        key: "id",
        width: 80
      },
      {
        title: "주류명",
        dataIndex: "itemKoName",
        key: "itemKoName",
        className: "min-width-table table-headline",
        ellipsis: true
      },
      {
        title: "용량",
        dataIndex: "itemVolume",
        key: "itemVolume",
        width: 80
      },
      {
        title: "판매유형",
        dataIndex: "boxSales",
        key: "boxSales",
        width: 90,
        render: (_, { boxSales }) => <Label value={boxSales ? "C" : "N"} title={boxSales ? "상자 단독" : "상자/본"} />
      },
      {
        title: "주류 랭킹",
        dataIndex: "disableRank",
        key: "disableRank",
        width: 90,
        render: (_, { disableRank }) => <Label value={disableRank ? "R" : "N"} title={disableRank ? "제외" : "포함"} />
      },
      {
        title: "추천 주류",
        dataIndex: "recommend",
        key: "recommend",
        width: 90,
        render: (_, { recommend }) => <Label value={recommend ? "GB" : "N"} title={recommend ? "등록" : "미등록"} />
      },
      {
        title: "강제노출",
        dataIndex: "forceShow",
        key: "forceShow",
        width: 90,
        render: (_, { forceShow }) => <Label value={forceShow ? "GB" : "N"} title={forceShow ? "등록" : "미등록"} />
      }
    ],
    []
  );

  const data = useMemo(() => items, [items]);
  const update = () => {
    const offset = items.length;
    let apiPath = `/api/getItem?limit=${limit}&offset=${offset}&search=${search}&boxSales=${boxSales}&disableRank=${disableRank}&type=${type}&recommend=${recommend}&forceShow=${forceShow}`;
    apiFetch(apiPath, "GET").then(data => {
      addItem(data[0]);
      setTotalLength(data[1]);
    });
  };
  const onClickFn = (row: any) => {
    apiFetch(`/api/getItem/${row.id}`, "GET").then(data => {
      setSelectItem(data);
    });
  };

  const onChangeFilter = (value: string) => {
    setSearch(value);
  };

  const onChangeBoxSales = (value: string) => {
    setBoxSales(value);
  };

  const onChangeState = (value: string) => {
    setDisableRank(value);
  };

  const onChangeType = (value: string) => {
    setType(value);
  };

  const onChangeRecommend = (value: string) => {
    setRecommend(value);
  };

  const onChangeForceShow = (value: string) => {
    setForceShow(value);
  };

  const newItem = () => {
    setSelectItem(undefined);
  };

  return (
    <PageWrapper>
      <ContentWrapper>
        <SearchWrapper>
          <InputFilter onChange={onChangeFilter} search={search} placeHolder="주류명" label="주류 검색" />
          <SelectFilter
            selectList={[{ label: "전체", value: "" }, ...itemTypes]}
            onChange={onChangeType}
            label={"주류 종류"}
            selectValue={type}
            width={200}
          />
          <RadioFilter selectList={filterList.boxSalesType} onChange={onChangeBoxSales} label={"판매유형"} selectValue={boxSales} />
          <RadioFilter selectList={filterList.rankingType} onChange={onChangeState} label={"랭킹노출"} selectValue={disableRank} />
          <RadioFilter selectList={filterList.registrationType} onChange={onChangeRecommend} label={"이달의 추천 주류"} selectValue={recommend} />
          <RadioFilter selectList={filterList.registrationType} onChange={onChangeForceShow} label={"강제노출"} selectValue={forceShow} />
        </SearchWrapper>
      </ContentWrapper>
      <TableHeaderWrapper>
        <TableHeader>{`품목정보 (총 ${totalLength}개)`}</TableHeader>
        <TableHeaderButtonWrapper>
          <Button title="+ 신규 품목 생성" onClick={newItem} />
        </TableHeaderButtonWrapper>
      </TableHeaderWrapper>
      <ContentWrapper style={{ overflow: "scroll" }}>
        <Table selectItem={selectItem} totalCount={totalLength} columns={columns} data={items} update={update} onClick={onClickFn} />
        <ItemForm item={selectItem} />
      </ContentWrapper>
    </PageWrapper>
  );
};

export default ItemPage;
